import {Env} from "./Env";
import useLocalStorage from "./storage";
import {useAxios} from "./Api";

export const useUser = () => {
    let userStorage = useLocalStorage("userData");
    let userTokenStorage = useLocalStorage("userDataToken");
    const api = useAxios();

    const initUser = () => {
        let userToken = userTokenStorage.getPrimitive();

        if(userToken) {
            Env.header.userToken = userToken;
            return getUserData();
        } else {
            userStorage.remove();
            return false;
        }
    }

    const personalDataSave = async (data) => {
        try {
            if(Env.header.userToken) {
                data.flora_cart_id = Env.cart_data?.flora_cart_id;
                let res = await api.put('/access/update-user', data);
                handleUpdateData(res.data.object);
                return res.data.object;
            } else {
                return doAccess(data);
            }
        } catch (e) {
            return null;
        }
    }

    const doAccess = async (data) => {
        try {
            data.flora_cart_id = Env.cart_data?.flora_cart_id;
            let res = await api.post('/access/simple-sign-on', data);
            handleUpdateData(res.data.object);
            return res.data.object
        } catch (e) {
            return null;
        }
    }

    const addressDataSave = async (data) => {
        try {
            data.flora_cart_id = Env.cart_data?.flora_cart_id;
            let res = await api.put('/access/update-user-address', data);
            handleUpdateData(res.data.object);
            return res.data.object;
        } catch (e) {
            console.log(e);
        }
    }

    const handleUpdateData = (data) => {
        userStorage.createStorageData(data);
        Env.user_data = data;
        if(data?.access?.token) {
            Env.header.userToken = data.access.token;
            userTokenStorage.setPrimitive(data.access.token);
        }
    }

    const getUserData = async (id) => {
        try {
            let res = await api.get('/access/get-user');
            handleUpdateData(res.data.object);
            return res.data.object;
        } catch (e) {
            Env.user_data = null;
            Env.header.userToken = null;
            userStorage.remove();
            userTokenStorage.remove();
            return null;
        }
    }

    return { initUser, addressDataSave, personalDataSave, getUserData};
};
