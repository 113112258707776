import {other} from "../SiteData";
import instaImg from "../media/images/instagram/1.jpg";
import instaImg2 from "../media/images/instagram/2.jpg";
import instaImg3 from "../media/images/instagram/3.png";
import instaImg4 from "../media/images/instagram/4.png";
import React from "react";

import {BsWhatsapp} from "react-icons/bs";
import {BiMoviePlay} from "react-icons/bi";
import {FaInstagram, FaPlay} from "react-icons/fa";
export const InstagramVideoList = () => {

    return (
        <section className="instagram-area-two" onClick={() => window.gtag && window.gtag("event", "watch_production_video_click")}>
            <div onClick={() => window.open(other.instagram, '_blank')}  className="row owl-theme m-0 p-0">
                <div className="col-6 col-md-3 ">
                    <div className={'sin-instagram rounded shadow-sm position-relative'}>
                    <div style={{position:'absolute', width: '100%', height: '100%', left: 0, bottom: 0, background: 'linear-gradient(360deg, white, transparent)'}}/>
                    <img style={{height: 400, width: '100%'}} src={instaImg} alt="" />
                    <div className="hover-text visible" style={{opacity: 1}}>
                        <a href={'https://www.instagram.com/reel/C0XSuTkpWJy/'} target={"_blank"}>
                            <span> <FaInstagram style={{margin: "auto", marginTop: 5, marginBottom: 5}}/>instagram</span>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-6 col-md-3 ">
                    <div className={'sin-instagram rounded shadow-sm'}>
                        <div style={{position:'absolute', width: '100%', height: '100%', left: 0, bottom: 0, background: 'linear-gradient(360deg, white, transparent)'}}/>

                    <img style={{height: 400, width: '100%'}} src={instaImg2} alt="" />
                    <div className="hover-text visible" style={{opacity: 1}}>
                        <a href={'https://www.instagram.com/reel/CyMVtYYJs4d'} target={"_blank"}>
                            <span> <FaInstagram style={{margin: "auto", marginTop: 5, marginBottom: 5}}/> instagram</span>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-6 col-md-3 ">
                    <div className={'sin-instagram rounded shadow-sm'}>
                        <div style={{position:'absolute', width: '100%', height: '100%', left: 0, bottom: 0, background: 'linear-gradient(360deg, white, transparent)'}}/>

                    <img style={{height: 400, width: '100%'}} src={instaImg3} alt="" />
                    <div className="hover-text visible" style={{opacity: 1}}>
                        <a href={'https://www.instagram.com/reel/C3BZ5TqJHT4/'} target={"_blank"}>
                            <span> <FaInstagram style={{margin: "auto", marginTop: 5, marginBottom: 5}}/> instagram</span>
                        </a>
                    </div>
                    </div>
                </div>
                <div className="col-6 col-md-3">
                    <div className={'sin-instagram rounded shadow-sm'}>
                        <div style={{position:'absolute', width: '100%', height: '100%', left: 0, bottom: 0, background: 'linear-gradient(360deg, white, transparent)'}}/>

                    <img style={{height: 400, width: '100%'}} src={instaImg4} alt="" />
                    <div className="hover-text visible" style={{opacity: 1}}>
                        <a href={'https://www.instagram.com/reel/CuNVcZhpaON/'} target={"_blank"}>
                            <span><FaInstagram style={{margin: "auto", marginTop: 5, marginBottom: 5}}/>instagram</span>
                        </a>
                    </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

