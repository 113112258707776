import Routes from "./Routes";
import React, {useEffect, useMemo, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import useLocalStorage from "./helpers/storage";
import {Env} from "./helpers/Env";
import {useCart} from "./helpers/useCart";
import {useUser} from "./helpers/useUser";

function App() {
    const [load, setLoad] = useState(false);
    let storage = useLocalStorage('uuid');
    const cart = useCart();
    const user = useUser();
    const configureUserId = () => {
        let storageUuid = storage.getPrimitive();
        if(!storageUuid) {
            storageUuid = uuidv4();
        }
        storage.setPrimitive(storageUuid);
        Env.user_uuid = storageUuid;
        setLoad(true);
    }

    const getApiData = async () => {
        await user.initUser();
        cart.initCart();
    }

    useEffect(() => {
        configureUserId();
        getApiData();
    },[]);

    if(!load) return true;

    return (
        <BrowserRouter>
            <Routes/>
        </BrowserRouter>
    )
}

export default App;
