import {toast} from "react-toastify";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import useLocalStorage from "./storage";

export const maskRealToView = (a) => {
    if (a) {
        return a?.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
    }
};


export const maskReal = (value) => {
    let aux = value;
    if(value !== '') {
        if(value[value.length - 2] === ',') {
            aux = value.replace(',0', '');
            aux = aux.slice(0, -1);
        } else {
            aux = value.replace(',00', '');
        }
    }
    let a = aux.replace(/[^0-9]/g, '');

    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = a.charAt(0) + ',00';
            break;
        case 2:
            a = a.charAt(0) + a.charAt(1) + ',00';
            break;
        case 3:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + ',00';
            break;
        case 4:
            a = a.charAt(0) + '.' + a.charAt(1) + a.charAt(2) + a.charAt(3) + ',00';
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',00';
            break;
        case 6:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + ',00';
            break;
        default:
            a =  a = a.charAt(0) + a.charAt(1) + a.charAt(2) + '.' + a.charAt(3) + a.charAt(4) + a.charAt(5) + ',00';
            break;
    }
    return a;
};

export const maskRealNu = (value) => {
    let a = value.replace(/[^0-9]/g, '');
    switch (a.length) {
        case 0:
            a = '';
            break;
        case 1:
            a = '0,0' + a.charAt(0);
            break;
        case 2:
            a = '0' + ',' + a.charAt(0) + a.charAt(1);
            break;
        case 3:
            a = a.charAt(0) + ',' + a.charAt(1) + a.charAt(2);
            break;
        case 4:
            if(a.charAt(0) === '0' && a.charAt(1) === '0') {
                a = a.charAt(0) + ',' + a.charAt(2) + a.charAt(3);
            } else if(a.charAt(0) === '0') {
                a = a.charAt(1) + ',' + a.charAt(2) + a.charAt(3);
            } else {
                a = a.charAt(0) + a.charAt(1) + ',' + a.charAt(2) + a.charAt(3);
            }
            break;
        case 5:
            a = a.charAt(0) + a.charAt(1) + a.charAt(2) + ',' + a.charAt(3) + a.charAt(4);
            break;
        case 6:
            a = a.charAt(0) + '.' + a.charAt(1) + a.charAt(2) + a.charAt(3) + ',' + a.charAt(4) + a.charAt(5);
            break;
        case 7:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',' + a.charAt(5) + a.charAt(6);
            break;
        default:
            a = a.charAt(0) + a.charAt(1) + '.' + a.charAt(2) + a.charAt(3) + a.charAt(4) + ',' + a.charAt(5) + a.charAt(6);
            break;
    }
    return a;
}
window.scrollToElement = (elementID) => {
    try {
        window?.scroll({
            top: document.querySelector(elementID).offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    } catch (e) {
        console.log(e)
    }
};
export const maskPhone = (item) => {
    if(item) {
        if(item.substring(0,3) === '+55') {
            item = item.substring(3);
        } else if(item.substring(0,2) === '55') {
            item = item.substring(2);
        }
        var x = item.replace(/\D/g, '').match(/(\d{2})(\d{5})(\d{4})/);
        if(x && x[1] && x[2] && x[3]) {
            item = '(' + x[1] + ') ' + x[2] + '-' + x[3];
        }
        return item;
    } return null;
};


export const globalMaskReal = (a) => {

    if(a) {
        let aux = a;
        if(typeof a === 'string') {
            aux = a.replace(',','.');
        }
        if(isNaN(aux)) {
            aux = aux.replace(/\./g, '');
        }
        aux = Number(aux).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        return aux.toString();
    } else {
        return '---';
    }
}
export const CountCart = () => {
    let queryClient = useQueryClient();
    let queryKey = ["count-cart"];
    const [cartItemCount, setCartItemCount] = useState(0);

    const updateCount = (newValue) => {
        queryClient.setQueryData(queryKey, () => (newValue))
    }

    const decrementCount = (newValue) => {
        queryClient.setQueryData(queryKey, () => (newValue))
    }

    useEffect(() => {
        let aux = queryClient.getQueryData(queryKey)
        setCartItemCount(aux)
    },[queryClient.getQueryData(queryKey)])

    return {updateCount, decrementCount, cartItemCount}
}

export const copyToClipboard = (text) => {
    let t = "Copiado com sucesso!"
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => {
                toast.success(t)
                console.log('Texto copiado para a área de transferência');
            })
            .catch(err => {
                console.error('Erro ao copiar para a área de transferência: ', err);
            });
    } else {
        let textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();

        try {
            let successful = document.execCommand('copy');
            let msg = successful ? t: 'Erro ao copiar para a área de transferência';
            toast.success(t)
        } catch (err) {
            console.error('Erro ao copiar para a área de transferência: ', err);
        } finally {
            document.body.removeChild(textarea);
        }
    }
}
