import {FaMinusCircle, FaWindowClose} from "react-icons/fa";
import {maskRealToView} from "../../helpers/Functions";
import React from "react";

export const CartProductListComponent = ({item, removeCart, disabled}) => {
return (
<>
    <div className={'p-1 pt-3 pb-3 p-md-3 position-relative rounded shadow-sm'} style={{borderLeft: '2px solid #b366f9'}}>
        {!disabled&&<div style={{position:"absolute", right: 10, top: 10}} onClick={() => removeCart()}>
            <FaWindowClose  style={{color: "gray", cursor: "pointer"}}/>
        </div>}
        <>
            <div className={'d-flex flex-wrap d-md-none'}>
                <div className="col-5 col-md-4 col-lg-5">
                    {item.product_variation.product_images.length > 0 &&
                        <img style={{ objectFit: 'cover', maxHeight:200}} className={'rounded shadow-sm'} src={item.product_variation.product_images[0].image_s3_url} alt=""/>
                    }
                </div>
                <div className={'col-7 col-md-4 col-lg-7 pl-0 pr-0'}>
                    <div className={''}>
                        <h6 style={{color: "black", fontWeight: "bold", marginBottom: 0}}>{item.product.name} - {item.product_variation.name}</h6>
                        <div style={{fontSize: 14, color: 'grey'}}> {item.product?.product_category.name}</div>
                        <div style={{fontSize: 14, color: 'gray'}}>Modelo {item.product_variation_size?.name}</div>
                        <div className={'text-right pr-2'}>
                            <span>1 unidade</span>
                        </div>
                    </div>
                    <div className={'pr-0 text-right mt-2 pr-2'}>
                        <div className={''}>
                            {item.product.discount > 0 &&
                                <div className={'d-flex align-items-center justify-content-end'}>
                                    <p className="price_from mb-0" style={{color: 'gray'}}>
                                        R$ {maskRealToView(Number(item.price))}
                                    </p>
                                    <span style={{color: '#673ab7', fontWeight: 500}}> &nbsp; {item.product.discount}% OFF</span>
                                </div>
                            }
                            <div className={''}>
                                <p className={'price text-success mb-0'} style={{fontWeight: 600, fontSize: 15}}>
                                    R$ {maskRealToView(item.price - ((item.product.discount / 100) * item.price))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className={'d-none flex-wrap d-md-flex'}>
                <div className={'d-flex'}>
                    {item.product_variation.product_images.length > 0 &&
                        <img style={{ objectFit: 'cover', maxHeight:80}} className={'rounded shadow-sm'} src={item.product_variation.product_images[0].image_s3_url} alt=""/>
                    }
                    <div className={'pl-3'}>
                        <h5 style={{color: "black", marginBottom: 0}}>{item.product.name} - {item.product_variation.name} (x1)</h5>
                        <div style={{fontSize: 14, color: 'grey'}}> {item.product?.product_category.name}</div>
                        <div style={{fontSize: 14, color: 'gray'}}>Tamanho <span style={{color: 'black', fontWeight: 500}}>{item.product_variation_size?.name}</span></div>
                    </div>
                </div>
                <div className={''} style={{flex: 1}}>

                    <div className={'pr-0 text-right mt-2 pr-0 h-100'}>
                        <div className={'h-100 d-flex flex-column justify-content-end'}>
                            {item.product.discount > 0 &&
                            <div className={'d-flex align-items-center justify-content-end'}>
                                <p className="price_from mb-0" style={{color: 'gray'}}>
                                    R$ {maskRealToView(Number(item.price))}
                                </p>
                                <span style={{color: '#673ab7'}}> &nbsp; {item.product.discount}% OFF</span>
                            </div>
                            }
                            <div>
                                <p className={'price text-success mb-0'} style={{fontWeight: 600, fontSize: 15}}>
                                    R$ {maskRealToView(item.price - ((item.product.discount / 100) * item.price))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    </div>
    {item.product?.product_bonus_main.map((subItem, index) =>
        <div key={index} className={'shadow-sm mt-2 ml-5 rounded bg-white '}>
            <div className={'d-flex d-md-none flex-wrap'}>
                <div className={'p-2 pt-2 p-md-3 '}>
                    {subItem.bonus_product.image_s3_url &&
                        <img style={{height:"100%", maxHeight:120, objectFit: 'cover'}}
                             className={'rounded shadow-sm'}
                             src={subItem.bonus_product.image_s3_url}
                             alt=""
                        />
                    }

                </div>

                <div style={{flex: 1}} className={'p-2'} >
                    <div className={'d-flex flex-column align-items-start'}>
                        <h6 style={{color: "black", fontWeight: 'bold', marginBottom: 0}}>{subItem.bonus_product.name} </h6>
                        <div style={{fontSize: 14, color: 'grey'}}>Brinde</div>
                        <div className={'text-center'}>
                            <span>1 unidade</span>
                        </div>
                    </div>


                    <div className={'d-flex justify-content-end flex-column text-right align-items-end mt-2'}>
                        <p className="price_from mb-1" style={{
                            color: 'gray'
                        }}>R$ {maskRealToView(Number(subItem.bonus_product.price))}</p>
                        <div className={'d-flex align-items-center'}>
                            <p className={'price text-success mb-0'} style={{fontWeight: 600}}>
                                R$ 0,00
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'d-none flex-wrap d-md-flex'}>
                <div className={'d-flex pl-3 p-lg-3'}>
                    {subItem.bonus_product.image_s3_url &&
                    <img style={{ objectFit: 'cover', maxHeight:80}} className={'rounded shadow-sm'} src={subItem.bonus_product.image_s3_url} alt=""/>
                    }
                    <div className={'pl-3'}>
                        <h5 style={{color: "black", marginBottom: 0}}>{subItem.bonus_product.name} (x1)</h5>
                        <div style={{fontSize: 14, color: 'grey'}}> Brinde</div>
                        <span style={{fontSize: 14, color: 'grey', limitRows: 1, lineClamp: 1}}> {subItem.bonus_product.description.slice(0,40)}...</span>
                    </div>
                </div>
                <div className={'pt-lg-3 pb-lg-3 pr-lg-3 ps-0'} style={{flex: 1}}>

                    <div className={'pr-0 text-right mt-2 pr-0 h-100'}>
                        <div className={'h-100 d-flex flex-column justify-content-end'}>
                            <div className={'d-flex align-items-center justify-content-end'}>
                                <p className="price_from mb-0" style={{ color: 'gray'}}>
                                    R$ {maskRealToView(Number(subItem.bonus_product.price))}
                                </p>
                                <span style={{color: '#673ab7'}}> &nbsp; {item.product.discount}% OFF</span>
                            </div>
                            <div>
                                <p className={'price text-success mb-0'} style={{fontWeight: 600}}>
                                     R$ 0,00
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
</>)
}
