import React, { useState, useEffect } from 'react';

function Countdown() {
    const [time, setTime] = useState({
        hours: 0,
        minutes: 60,
        seconds: 0
    });

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (time.minutes === 0 && time.seconds === 0) {
                clearInterval(countdownInterval);
                // Aqui você pode adicionar qualquer lógica que deseja executar quando o contador chegar a zero
                console.log('Contagem regressiva concluída!');
            } else {
                setTime(prevTime => {
                    const updatedSeconds = prevTime.seconds === 0 ? 59 : prevTime.seconds - 1;
                    const updatedMinutes = prevTime.seconds === 0 ? prevTime.minutes - 1 : prevTime.minutes;
                    return {
                        hours: prevTime.hours,
                        minutes: updatedMinutes,
                        seconds: updatedSeconds
                    };
                });
            }
        }, 1000);

        return () => clearInterval(countdownInterval);
    }, [time]);

    const formatTime = value => {
        return value < 10 ? `0${value}` : value;
    };

    return (
        <div className={'d-flex flex-column align-items-center  mt-3'}>
            <span>Tempo Restante</span>
            <strong style={{fontSize: 40}}>   {`${formatTime(time.hours)}:${formatTime(time.minutes)}:${formatTime(time.seconds)}`}</strong>
        </div>
    );
}

export default Countdown;
