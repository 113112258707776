import {useMemo} from "react";
import { ErrorMessage as ErrorHookForm}  from "@hookform/error-message"


export default function ErrorMessage(props) {
    return useMemo(() => (
        <p className={`is-invalid-text m-0 ${props.errors[props.name] ? "show" : ""}`}>
            <ErrorHookForm {...props}/>
        </p>

    ), [props.errors[props.name]]);
}