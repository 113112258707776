import {copyToClipboard, maskRealToView} from "../../helpers/Functions";
import React, {useEffect, useRef} from "react";
import {FaCopy} from "react-icons/fa";
import {useAxios} from "../../helpers/Api";
import {useCart} from "../../helpers/useCart";
import {useHistory} from "react-router-dom";
import Countdown from "../Countdown";
import pixBanco from "../../media/images/pixbanco.png"

export const PixComponent = ({pixValue, url, code, show}) => {

    const intervalID = useRef('');
    const cartHook = useCart();
    const history = useHistory();

    const startPixTimer = () => {
        intervalID.current = setInterval(async () => {
            try {
                let res = await cartHook.getCartData();

                if(res?.step === 'payment-done') {
                    cartHook.removeCart();
                    history.push('/pedido-concluido/'+res.identifier +window.location.search);
                }
            } catch (e) {
                console.log(e);
            }
        }, 2000);
    }

    useEffect(() => {
        if(show) {
            startPixTimer()
        } else {
            if(intervalID.current !== '') {
                clearInterval(intervalID.current)
            }
        }
    }, [show]);

    useEffect(() => {
        return () => {
            if(intervalID.current !== '') {
                clearInterval(intervalID.current)
            }
        }
    }, []);

    return (
        <div className={'bg-white p-3 rounded'}>
            <h4 style={{fontWeight: 700, color: "black"}} className={'mb-3 text-center'}>
                Pague com o PIX
            </h4>
            <h6 className={'text-center'}>
                Digitalize o <strong className={'text-primary'}>QR Code</strong> com seu telefone celular
            </h6>
            <Countdown/>
            <div className={'text-center bg-white rounded p-4'}>
                <img src={url}
                     alt=""
                     className={'w-100'}
                />

                <div className={'my-3 text-center w-100 d-flex justify-content-center'}>
                    <button className={'btn btn-flora-primary rounded-pill d-flex align-items-center'}
                            style={{borderRadius: 20}} onClick={() => copyToClipboard(code)}>
                        <FaCopy style={{marginRight: 7}}/>
                        COPIAR PIX
                    </button>
                </div>

                <div className={'d-flex justify-content-center'}>
                    <img src={pixBanco}
                         alt=""
                         style={{height: 80}}
                    />
                </div>

            </div>

            <div>
                <small>
                    Procure em seu aplicativo de banco ou conta digital a
                    funcionalidade e escaneie o QR Code ao lado ou copie o código
                    usando o Pix Copia e Cola para efetuar um pagamento.
                </small>
            </div>

        </div>
    )
}
