import React from 'react'
import icona from '../../../media/images/icon/a.png'
import iconb from '../../../media/images/icon/b.png'
import iconc from '../../../media/images/icon/c.png'
import icond from '../../../media/images/icon/d.png'
import Slider from "react-slick";
export const BannerDifferentialsComponent = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className={'banner-differentials pb-0'}>
            <div className={'container'}>
                <h3 className={'text-center mb-4 font-weight-super-light text-white '} style={{letterSpacing: 3,}}>DIFERENCIAIS FLORA</h3>
                <Slider {...settings}>
                    <div className="">
                        <div className={'text-center d-flex flex-column justify-content-between align-items-center h-100'}>

                            <img src={icona} style={{height: 35, width: 35}} alt=""/>
                            <div className={'mt-4 px-4'}>
                                <h6>
                                    Consultoria Gratuita
                                </h6>
                                <small>
                                    Oferecemos consultoria gratuita para garantir que você encontre a peça perfeita para cada ocasião.
                                </small>
                            </div>
                        </div>

                    </div>
                    <div className="">
                       <div className="text-center d-flex flex-column justify-content-between align-items-center h-100">

                           <img src={iconb} style={{height: 35, width: 35}} alt=""/>
                           <div  className={'mt-4 px-4'}>
                               <h6>
                                   Sob Medida
                               </h6>
                               <small>
                                   Peças personalizadas feitas sob medida, garantindo um encaixe perfeito e um toque único.
                               </small>
                           </div>
                       </div>
                    </div>
                    <div className="">
                        <div className={'text-center d-flex flex-column justify-content-between align-items-center h-100'}>

                            <img src={iconc} style={{height: 35, width: 35}} alt=""/>

                            <div  className={'mt-4 px-4'}>
                                <h6>
                                    Envio Rápido
                                </h6>
                                <small>
                                    Despachamos seu pedido em até dois dias úteis, garantindo rapidez e eficiência no envio dos seus pedidos.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="">
                       <div className={'text-center d-flex flex-column justify-content-between align-items-center h-100'}>

                           <img src={icond} style={{height: 35, width: 35}} alt=""/>
                           <div  className={'mt-4 px-4'}>
                               <h6>
                                   Qualidade
                               </h6>
                               <small>
                                   Utilizamos tecidos de alta qualidade e prezamos por um acabamento impecável, com atenção aos detalhes em cada etapa do processo.
                               </small>
                           </div>
                       </div>
                    </div>
                </Slider>
            </div>
        </section>
    )
}
