import React from "react";
import {BsWhatsapp} from "react-icons/bs";
export const WhatsAppButton = () => {

    return (
        <a  onClick={() => window.gtag &&window.gtag("event", "whats_fixed_click")} href={window.innerWidth < 768? 'https://wa.me/5567991797649' : 'https://web.whatsapp.com/send?phone=5567991797649'}
           target={'_blank'}
           className="whatsapp-fixed">
            <BsWhatsapp size={25}/>
        </a>
    )
}
