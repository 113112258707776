const useLocalStorage = (key) => {

    const createStorageData = (value) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const setPrimitive = (value) => {
        window.localStorage.setItem(key, value);
    };

    const getPrimitive = () => {
        return window.localStorage.getItem(key);
    };

    const updateStorageData = (value) => {
        window.localStorage.setItem(key, JSON.stringify(value));
    };

    const getStorageData = () => {
        try {
            return JSON.parse(window.localStorage?.getItem(key));
        } catch (e) {
            remove();
            return null;
        }
    };

    const remove = () => {
        window.localStorage.removeItem(key);
    }

    return {setPrimitive, createStorageData, getPrimitive, getStorageData, updateStorageData, remove}
};

export default useLocalStorage;
