import React from "react";
import {FaDotCircle} from "react-icons/fa";


export const TermsPage = () => {

    return (
        <div className={'my-20'} style={{paddingTop: "180px"}}>
            <div className="container">
                <h1>Trocas e Devoluções</h1>

                <p>Olá,</p>

                <p>Todas as nossas peças são produzidas após o seu pedido. Para minimizar trocas e devoluções,
                    procuramos abastecer nosso site com fotos que retratam fielmente os nossos produtos, juntamente com
                    descrições detalhadas. Caso haja alguma dúvida antes da compra, oferecemos atendimento personalizado
                    pelo nosso WhatsApp: (67) 9 9179-7649.</p>

                <p>Antes de finalizar a compra, pedimos que verifique o nosso prazo de postagem, que é de no máximo 5
                    dias úteis após a confirmação do pagamento.</p>

                <p className="highlight">ATENÇÃO: Todos os fretes de troca ou devolução são por conta do cliente!</p>

                <h2>Trocas</h2>

                <p>As trocas devem ser realizadas em até 7 dias corridos após o recebimento do pedido, e a solicitação
                    deve ser feita pelo nosso WhatsApp (67) 9 9179-7649 dentro deste prazo.</p>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span style={{flex: 1}}>Não aceitamos peças cuja solicitação de troca ou devolução não tenha sido realizada previamente.</span>
                </div>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span  style={{flex: 1}}>As etiquetas devem estar afixadas nas peças.</span>
                </div>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span  style={{flex: 1}}>Não realizamos trocas de peças em promoção, nem devoluções.</span>
                </div>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span  style={{flex: 1}}>As peças não podem apresentar nenhum indício de uso ou alterações, tais como odores, manchas ou
                    ajustes de costura</span>
                </div>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span  style={{flex: 1}}>O frete de reenvio das trocas é por conta do cliente.</span>
                </div>

                <div className={'d-flex'}>
                    <FaDotCircle size={15} className={'mr-2 mt-1'}/><span  style={{flex: 1}}>Peças com qualquer tipo de personalização não estão sujeitas a trocas ou devoluções.</span>
                </div>

{/*
                <h2>Devolução</h2>

                <p>As peças devem estar em conformidade com todos os termos acima.</p>
                <p>A solicitação deve ser feita através do nosso WhatsApp (67) 9 9179-7649.</p>
                <p>Após o recebimento e conferência das peças, o estorno será realizado da mesma forma que o pagamento
                    foi efetuado (PIX ou cartão) dentro de até 5 dias úteis.</p>
                <p>Certifique-se de que todas as condições acima estão sendo cumpridas, pois todas as peças são
                    conferidas ao chegar. Caso não estejam dentro dos termos de troca/devolução, os produtos serão
                    reencaminhados ao cliente e será cobrado um novo frete, perdendo assim o direito à
                    troca/devolução.</p>

                <p>Em caso de dúvidas, teremos o maior prazer em atendê-lo. Fazemos tudo com muito amor!</p>*/}
            </div>
        </div>
    )
}
