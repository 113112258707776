import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ErrorMessage from "../ErrorMessage";
import NumberFormat from "react-number-format";
import React, {useState} from "react";
import {FaArrowRight} from "react-icons/fa";
import {useUser} from "../../helpers/useUser";
import {Env} from "../../helpers/Env";

export const PersonalDataComponent = ({handleChangeActiveAccordion, disabled}) => {
    const personalData = yup.object().shape({
        name: yup.string().required('O nome é obrigatório'),
        contact_mobile_phone: yup.string().required('Informe seu número de telefone').min(11, 'Insira um número válido'),
        contact_mail: yup.string().email('Insira um e-mail válido').required('O email é obrigatório'),
        cpfcnpj: yup.string().trim().required('CPF é obrigatório').matches(/^\d{11}$/, 'CPF inválido'),
    });
    const user = useUser();
    const { register, handleSubmit, formState: { errors,   }, control} = useForm({
        resolver: yupResolver(personalData),
        defaultValues: {
            name: Env.user_data?.person?.name,
            contact_mobile_phone: Env.user_data?.person?.contact_mobile_phone,
            contact_mail: Env.user_data?.person?.contact_mail,
            cpfcnpj: Env.user_data?.person?.cpfcnpj,
        },
    });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        if(!loading) {
            if(disabled) {
                handleChangeActiveAccordion("address")
            } else {
                setLoading(true);
                let res = await user.personalDataSave(data)
                console.log(res);
                if(res) {
                    handleChangeActiveAccordion("address")
                }
                console.log(res);
                setLoading(false);
            }
        }
    }

    const onError = (error) => {
        console.log(error)
    }
    return (
        <div className="d-flex flex-column justify-content-center mb-10">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div>
                    <label className={'form-label'} htmlFor="name">Nome</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><i
                                className="bi bi-people fs-1 text-dark"></i>
                            </span>
                        </div>

                        <input
                            name="name"
                            id={"name"}
                            disabled={disabled}
                            className={`form-control ${errors?.name?.message ? ' is-invalid' : ''}`}
                            placeholder="Seu nome"
                            {...register("name")}
                        />
                    </div>
                    <ErrorMessage errors={errors}
                                  name="name"
                    />
                </div>

                <div className={'mt-2'}>
                    <label className={'form-label'} htmlFor="contact_mobile_phone">Celular</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"
                                  id="basic-addon1">
                                <i className="bi bi-phone fs-1 text-dark"></i>
                            </span>
                        </div>
                        <Controller name='contact_mobile_phone'
                                    control={control}
                                    render={({field: {onChange, value}}) => (<NumberFormat
                                        className={`form-control ${errors?.contact_mobile_phone?.message ? ' is-invalid' : ''}`}
                                        id={'contact_mobile_phone'}
                                        name={'Celular'}
                                        value={value}
                                        disabled={disabled}
                                        onValueChange={(e) => onChange(e.value)}
                                        placeholder='Telefone'
                                        format={'(##) #####-####'}
                                        mask="_"
                                    />)}
                        />
                    </div>
                    <ErrorMessage errors={errors}
                                  name="contact_mobile_phone"
                    />
                </div>

                <div className={'mt-2'}>
                    <label className={'form-label'} htmlFor="cpfcnpj">CPF</label>
                    <div className="input-group ">
                        <div className="input-group-prepend">
                            <span className="input-group-text"
                                  id="basic-addon1"
                            >
                             <i className="bi bi-shield-lock fs-1 text-dark"></i></span>
                        </div>

                        <Controller name='cpfcnpj'
                                    control={control}
                                    render={({field: {onChange, value}}) => (
                                        <NumberFormat
                                            className={`form-control ${errors?.cpfcnpj?.message ? ' is-invalid' : ''}`}
                                            id={'cpfcnpj'}
                                            name={'cpfcnpj'}
                                            value={value}
                                            disabled={disabled}
                                            onValueChange={(e) => onChange(e.value)}
                                            placeholder='Digite seu CPF'
                                            format={'###.###.###-##'}
                                            mask="_"
                                        />
                                    )}
                        />
                    </div>
                    <ErrorMessage errors={errors}
                                  name="cpfcnpj"
                    />
                </div>

                <div className={' mt-2'}>
                    <label className={'form-label'} htmlFor="contact_mail">Email</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"
                                  id="basic-addon1">
                                <i className="bi bi-mailbox fs-1 text-dark"></i>
                            </span>
                        </div>

                        <input name="contact_mail"
                               id={"contact_mail"}
                               className={`form-control ${errors?.contact_mail?.message ? ' is-invalid' : ''}`}
                               placeholder="Email"
                               disabled={disabled}
                               {...register("contact_mail")}
                        />
                    </div>
                    <ErrorMessage errors={errors}
                                  name="contact_mail"
                    />
                </div>

                <div className="d-flex justify-content-end align-items-center">
                    <button type={"submit"} className={'btn d-flex align-items-center btn-success mt-3 '} style={{backgroundColor: '#3fc583'}}>
                        Continuar
                        <FaArrowRight style={{marginLeft: 10}}/>
                    </button>
                </div>
            </form>
        </div>

    )
}
