import React from 'react'
import {Link} from "react-router-dom";
import {BiSolidCartAdd} from "react-icons/bi";
import Slider from "react-slick";
import {maskRealToView} from "../helpers/Functions";

const ProductsComponentCarousel = ({data, newCategory}) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={'categories p-0'} >
            <Slider {...settings}>
                {data?.map((item, index) => (
                    <div className={''} key={index}>
                        <Link to={`/produto/${item.flora_product_variation_id}`+window.location.search} style={{color:'inherit'}} className={'shadow-hover h-100 w-100'}>
                            <div className="card h-100  border-0">
                                <div className="card-body p-0 h-100">
                                    <div className={'d-flex flex-column h-100 product'}>
                                        <div className={'position-relative'}>
                                            {item.product_images.length > 0 &&<img src={item.product_images[0].image_s3_url} alt=""/>}
                                            {item.product.discount > 0&&
                                                <div className={'d-flex position-absolute'} style={{top: 10, right: 10}}>
                                                    <div className={'p-2 bg-white shadow-sm rounded'} style={{fontWeight: 'bolder'}}>
                                                        <span className={'font-weight-bold text-dark'}>{item.product.discount}%</span><br/>
                                                        <span>OFF</span>
                                                    </div>
                                                </div>}

                                            <div className={'d-flex flex-wrap position-absolute'}
                                                 style={{bottom: 10, left: 10}}>



                                                {item.product.product_bonus_main.map((item, index) =>
                                                    <div className={'p-1 px-2 mr-2 bg-white '}
                                                         style={{
                                                             background: '#fdf7ff',
                                                             boxShadow: '2px 1px 3px 1px #0000000f',
                                                             borderRadius: '5px',
                                                             fontWeight: 400,
                                                             color: 'black'
                                                         }}>
                                                        <span>{item.bonus_product.name} <strong>Grátis</strong></span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className={'d-flex align-items-center justify-content-end mt-2 pr-2'}>
                                            {/* <div className={'px-2'} style={{border: '1px solid rgb(225 209 255)', backgroundColor: '#f2ebff',  borderRadius: 20}}>
                                               <small style={{color: '#673ab7'}}>{item.name}</small>
                                           </div>*/}
                                        </div>
                                        <div className={'px-3 pt-2 text-center'}>
                                            <h4 className={'limit-rows clamp-1'}>{item.product.name} <small>({item.name})</small></h4>
                                            <div className={'d-flex'}>
                                               <span style={{fontSize: 14, color: 'rgb(123, 123, 123)'}}
                                                     className={'limit-rows clamp-3 descip'}>{item.product.description}</span>
                                            </div>
                                        </div>
                                        <div className={'d-flex align-items-center flex-column justify-content-between mt-auto'}>
                                            <div className={'p-3 text-center'}>
                                                {item.product.discount > 0 &&
                                                    <p className="price_from ">R$ {maskRealToView(Number(item.product.price))}</p>
                                                }
                                                <div className={'d-flex align-items-center justify-content-center'}>
                                                    <p className={'price'}>
                                                        R$ {maskRealToView(item.product.price - ((item.product.discount/100) * item.product.price))}
                                                    </p>
                                                </div>

                                                <span className={'sub-price'}>
                                                   em até 10x de <strong> R$ {maskRealToView(Number((item.product.price - ((item.product.discount/100) * item.product.price))/10))} </strong> sem juros
                                               </span>

                                            </div>
                                            <div className={'d-flex align-items-center'}>
                                               <span  className="btn btn-light d-flex align-items-center">
                                                   <span style={{letterSpacing: '2px'}}>COMPRAR</span>
                                               </span>
                                                <span  className="btn ml-2 btn-outline-success border-2 border d-flex align-items-center">
                                                   <BiSolidCartAdd size={20} />
                                               </span>
                                            </div>


                                        </div>

                                        <span style={{fontSize: 13, textTransform: 'capitalize', fontWeight:600, color:'#00a650', gap:5}} className={'d-flex align-items-center px-3 pb-3'}>

                                   </span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ProductsComponentCarousel
