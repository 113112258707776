export const Env = {
    debug: process.env.NODE_ENV === 'production' ? false : true,
    ambience: process.env.NODE_ENV  === 'production' ? 'prod' : 'dev',
    api_url: process.env.NODE_ENV  === 'production'? 'https://sistema.atelieflora.com' : 'https://sistema.atelieflora.com',
    header: {
        'X-Client-Device': 'web',
        Accept: 'application/json',
        'X-Version-Name': '2.0.0',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },

    TRACKING_ID: 'G-9RQB713QZ1',
    phone_validation: false,
    version: '1.0.0',
    application_title: '',
    application_name: 'com.crafty.contrata-mais.web',
    application_alias: '',
    state_restriction: '',
    state_full: '',
    support_number: '',
    support_email: '',
};
