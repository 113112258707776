import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {FaEdit, FaUsps} from "react-icons/fa";
import {FaPencil} from "react-icons/fa6";

const AccordionContext = createContext();
const AccordionGroupContext = createContext();

export const removeShow = (newValue) => {
    let newElement = document.getElementById(`${newValue}-button`)

    if (newElement) {
        newElement.click();
    }
}

const Root = ({children, id,className, onMenuSelected}) => {
    const selectedId = (e) => {
        // console.log(completedSteps)
        // console.log(e)
        onMenuSelected(e)
        removeShow(e)
    }

    return (
        <AccordionContext.Provider value={{id, selectedId}}>
            <div className={(className || "") + " card root"}>
                {children}
            </div>
        </AccordionContext.Provider>
    )
}

Root.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.array,
    className: PropTypes.string,
};

const Group = ({children, completedSteps}) => {
    return (
        <AccordionGroupContext.Provider value={{completedSteps: completedSteps}}>
            <div id="accordion">
                {children}
            </div>
        </AccordionGroupContext.Provider>
    )
}

Group.propTypes = {
    completedSteps: PropTypes.array.isRequired,
};
const Header = ({children, completed, activeMenu, onClick}) => {
    const {id, selectedId} = useContext(AccordionContext);
    const {completedSteps} = useContext(AccordionGroupContext);

    useEffect(() => {
        if (activeMenu === id) {
            removeShow(id)
        }
    }, []);

    return (
        <div className={`card-header bg-transparent ${activeMenu === id ? "in-progress" : (completedSteps?.includes(id)) ? "completed" : " "}`}>
            <div className={'d-flex justify-content-between'}>
                <div>
                    {children}
                </div>

                <div className={`${completedSteps?.includes(id) ? "d-flex" : "d-none"} align-items-center`} style={{gap:5}}>
                    <i className="fa fa-check-circle text-success" style={{fontSize: 20}}/>
                    <button className={"btn p-0 text-left button-edit"}
                            style={{background:"transparent"}}
                            data-toggle="collapse"
                            data-target={`#${id}`}
                            id={`${id}-button`}
                            aria-controls={`${id}`}
                            aria-expanded="false"
                            onClick={() => {
                                selectedId(id);
                                if (onClick) {
                                    onClick()
                                }
                            }}
                    >
                        <FaPencil/>
                    </button>
                </div>

            </div>
        </div>
  )
}

const Body = ({children, isOpen}) => {
    const {id} = useContext(AccordionContext);

    return (
        <div id={`${id}`}
             className={`collapse`}
             aria-labelledby={`${id}`}
             data-parent="#accordion"
        >
            <div className="card-body">
                {children}
            </div>

        </div>
    )
}

export const Accordion = {
    Root: Root,
    Header: Header,
    Body: Body,
    Group:Group,
}
