import React, {useCallback, useRef} from 'react'
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import {SliderProductsList} from "../../../SiteData";
import {Swiper, SwiperSlide} from "swiper/react";

export const SubBanner = () => {
    const sliderRef = useRef(null)

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    return (
        <div className="section-informative-banners">
            <Swiper
                ref={sliderRef}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={1}
                pagination={{ clickable: true, type: 'bullets' }}
                loop={true}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >

                <SwiperSlide>
                    <div className="">
                        <div className="service-item mx-3 justify-content-center d-flex flex-column flex-lg-row text-center text-lg-left">
                            <div>
                                <svg className="" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 51 51">
                                    <path
                                        d="M25.5,0A25.5,25.5,0,1,0,51,25.5,25.53,25.53,0,0,0,25.5,0Zm0,50A24.5,24.5,0,1,1,50,25.5,24.53,24.53,0,0,1,25.5,50ZM39.11,28.83l-1.6.58L33,16.88a.53.53,0,0,0-.26-.29.5.5,0,0,0-.38,0l-12.64,4.6-1.11-3c0-.12-.71-2.84-3-2.91-1.06,0-4.93.06-5.09.06a.51.51,0,0,0-.49.51.55.55,0,0,0,.52.49s4-.1,5-.06c1.53.05,2.06,2.12,2.08,2.2l5.12,14.08a4,4,0,1,0,4.63,1.65l12.09-4.4a.5.5,0,1,0-.34-.94ZM20,22.12,25.9,20v0l1.6,4.39a.49.49,0,0,0,.47.33.51.51,0,0,0,.17,0,.5.5,0,0,0,.3-.64l-1.6-4.39v0l5.35-1.94,4.39,12.06-9.9,3.61A4,4,0,0,0,24,32.3l-.25,0ZM24,39.3a3,3,0,1,1,3-3A3,3,0,0,1,24,39.3Z">
                                    </path>
                                </svg>
                            </div>
                            <div >
                                <h3 className="h5 m-0">Qualidade e conforto</h3>
                                <p className="m-0">Buscamos sempre a melhor qualidade e conforto em todas as peças</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="">
                        <div className="service-item mx-3 justify-content-center d-flex flex-column flex-lg-row  text-center text-lg-left">
                            <div>
                                <svg className="" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 51 51">
                                    <path
                                        d="M25.5,0A25.5,25.5,0,1,0,51,25.5,25.53,25.53,0,0,0,25.5,0Zm0,50A24.5,24.5,0,1,1,50,25.5,24.53,24.53,0,0,1,25.5,50ZM39.11,28.83l-1.6.58L33,16.88a.53.53,0,0,0-.26-.29.5.5,0,0,0-.38,0l-12.64,4.6-1.11-3c0-.12-.71-2.84-3-2.91-1.06,0-4.93.06-5.09.06a.51.51,0,0,0-.49.51.55.55,0,0,0,.52.49s4-.1,5-.06c1.53.05,2.06,2.12,2.08,2.2l5.12,14.08a4,4,0,1,0,4.63,1.65l12.09-4.4a.5.5,0,1,0-.34-.94ZM20,22.12,25.9,20v0l1.6,4.39a.49.49,0,0,0,.47.33.51.51,0,0,0,.17,0,.5.5,0,0,0,.3-.64l-1.6-4.39v0l5.35-1.94,4.39,12.06-9.9,3.61A4,4,0,0,0,24,32.3l-.25,0ZM24,39.3a3,3,0,1,1,3-3A3,3,0,0,1,24,39.3Z">
                                    </path>
                                </svg>
                            </div>
                            <div >
                                <h3 className="h5 m-0">Despacho ultra-rápido</h3>
                                <p className="m-0">Despachamos em até 2 dias úteis</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="">
                        <div className="service-item mx-3 justify-content-center d-flex flex-column flex-lg-row  text-center text-lg-left">
                            <div>
                                <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
                                    <path d="M23.88,25.45a.51.51,0,0,0,.5-.5V23.06a1.32,1.32,0,0,0-1.32-1.31H19.81a1.31,1.31,0,0,0-1.31,1.31v3.25a1.31,1.31,0,0,0,1.31,1.31h3.25a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H19.81a.31.31,0,0,1-.31-.31V23.06a.31.31,0,0,1,.31-.31h3.25a.32.32,0,0,1,.32.31V25A.5.5,0,0,0,23.88,25.45Z
                                        M23.56,33.62a.5.5,0,0,0-.5-.5H14.12A1.12,1.12,0,0,1,13,32V20l7.58-6.71a1.6,1.6,0,0,1,.86-.33,1.6,1.6,0,0,1,.86.33l4.49,4a.51.51,0,0,0,.54.08.5.5,0,0,0,.29-.46v-2a.33.33,0,0,1,.32-.32h1.62a.33.33,0,0,1,.32.32v4.87a.53.53,0,0,0,.16.38l3,2.66a.51.51,0,0,0,.71-.05.49.49,0,0,0,0-.7l-2.83-2.51V14.94a1.32,1.32,0,0,0-1.32-1.32H27.94a1.32,1.32,0,0,0-1.32,1.32v.88L23,12.58a2.28,2.28,0,0,0-3,0L9.16,22.1a.51.51,0,0,0,0,.71.51.51,0,0,0,.7,0L12,20.92V32a2.12,2.12,0,0,0,2.12,2.12h8.94A.5.5,0,0,0,23.56,33.62Z
                                        M41.21,35.25h-1.9V27.13a1.55,1.55,0,0,0-1.46-1.63H26.15a1.55,1.55,0,0,0-1.46,1.63v8.12h-1.9a.54.54,0,0,0-.54.54v.54a2.17,2.17,0,0,0,2.16,2.17H39.59a2.17,2.17,0,0,0,2.16-2.17v-.54A.54.54,0,0,0,41.21,35.25ZM25.69,27.13c0-.34.21-.63.46-.63h11.7c.25,0,.46.29.46.63v8.12H25.69Zm15.06,9.2a1.18,1.18,0,0,1-1.16,1.17H24.42a1.18,1.18,0,0,1-1.17-1.17v-.08h17.5Z
                                        M25.5,0A25.5,25.5,0,1,0,51,25.5,25.53,25.53,0,0,0,25.5,0Zm0,50A24.5,24.5,0,1,1,50,25.5,24.53,24.53,0,0,1,25.5,50Z">
                                    </path>
                                </svg>
                            </div>
                            <div>
                                <h3 className="h5 m-0">
                                    Parcele em até 10x sem juros no cartão
                                </h3>
                                <p className="m-0">Válido para todas as compras</p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <div className="service-item mx-3 justify-content-center d-flex flex-column flex-lg-row  text-center text-lg-left">
                            <div>
                                <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51">
                                    <path d="M23.88,25.45a.51.51,0,0,0,.5-.5V23.06a1.32,1.32,0,0,0-1.32-1.31H19.81a1.31,1.31,0,0,0-1.31,1.31v3.25a1.31,1.31,0,0,0,1.31,1.31h3.25a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H19.81a.31.31,0,0,1-.31-.31V23.06a.31.31,0,0,1,.31-.31h3.25a.32.32,0,0,1,.32.31V25A.5.5,0,0,0,23.88,25.45Z
                                        M23.56,33.62a.5.5,0,0,0-.5-.5H14.12A1.12,1.12,0,0,1,13,32V20l7.58-6.71a1.6,1.6,0,0,1,.86-.33,1.6,1.6,0,0,1,.86.33l4.49,4a.51.51,0,0,0,.54.08.5.5,0,0,0,.29-.46v-2a.33.33,0,0,1,.32-.32h1.62a.33.33,0,0,1,.32.32v4.87a.53.53,0,0,0,.16.38l3,2.66a.51.51,0,0,0,.71-.05.49.49,0,0,0,0-.7l-2.83-2.51V14.94a1.32,1.32,0,0,0-1.32-1.32H27.94a1.32,1.32,0,0,0-1.32,1.32v.88L23,12.58a2.28,2.28,0,0,0-3,0L9.16,22.1a.51.51,0,0,0,0,.71.51.51,0,0,0,.7,0L12,20.92V32a2.12,2.12,0,0,0,2.12,2.12h8.94A.5.5,0,0,0,23.56,33.62Z
                                        M41.21,35.25h-1.9V27.13a1.55,1.55,0,0,0-1.46-1.63H26.15a1.55,1.55,0,0,0-1.46,1.63v8.12h-1.9a.54.54,0,0,0-.54.54v.54a2.17,2.17,0,0,0,2.16,2.17H39.59a2.17,2.17,0,0,0,2.16-2.17v-.54A.54.54,0,0,0,41.21,35.25ZM25.69,27.13c0-.34.21-.63.46-.63h11.7c.25,0,.46.29.46.63v8.12H25.69Zm15.06,9.2a1.18,1.18,0,0,1-1.16,1.17H24.42a1.18,1.18,0,0,1-1.17-1.17v-.08h17.5Z
                                        M25.5,0A25.5,25.5,0,1,0,51,25.5,25.53,25.53,0,0,0,25.5,0Zm0,50A24.5,24.5,0,1,1,50,25.5,24.53,24.53,0,0,1,25.5,50Z">
                                    </path>
                                </svg>
                            </div>
                            <div >
                                <h3 className="h5 m-0">Pague via PIX e ganhe 5% OFF</h3>
                                <p className="m-0">
                                    Não quer usar seu cartão de crédito? Pague via PIX
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>

    )
}
