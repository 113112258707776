import React, {useRef, useState} from 'react';
import {CollectionProducts, interpreter, other, PersonalizedDress} from "./SiteData";
import {Footer} from "./Footer";
import bandeiras from './media/images/bandeiras.png'
import sewImage from "./media/sew.png";
import Slider from "react-slick";
import {useHistory} from "react-router-dom";
import whatsWhite from "./media/whatsapp.png";
import bike from "./media/images/bike.png";
import {InstagramVideoList} from "./components/InstagramVideoList";
import branch from "./media/flower.png";
import videoCustom from "./media/video/custom.mp4";

const ProductPageCustom = (props) => {

    const history = useHistory()

    return (
        <div>
            <div className={'pt-3 pt-lg-0 pt-xl-3'}>
                <section className="breadcrumb-area" id={'bread'}>
                    <div className="container-fluid custom-container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bc-inner mt-4">
                                    <p><a href="#" onClick={() => history.push('/'+window.location.search)}>Home |</a> Personalizar</p>
                                </div>
                            </div>
                            {/* /.col-xl-12 */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container */}
                </section>
            </div>
            <div className={'d-flex flex-column container pl-0 pr-0 pb-5 mt-0 text-center'} style={{marginTop: 74}}>
                <div style={{height: 400, width: '100%', objectFit: 'cover', position: 'relative',}}>
                    <video autoPlay loop muted playsInline style={{height: 400, position: 'absolute', zIndex: -1, left: 0, bottom: 0, width: '100%', objectFit: 'cover'}}>
                        <source src={videoCustom} type="video/mp4"/>
                    </video>
                    <div  style={{height: 400, backgroundColor: "#000000", opacity: 0.5, position: 'absolute', zIndex: -1, left: 0, bottom: 0, width: '100%', objectFit: 'cover'}} />
                    <div style={{zIndex: 29, height: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
                        <h3 style={{color: 'white', marginBottom: 20}}>Vestido Personalizado</h3>
                        <span style={{color: '#e9e9e9'}}>Gostaria de fazer uma peça única? entre em contato comigo que vou te ajudar a encontrar a melhor combinação!</span>
                    </div>

                </div>


                <div className={'d-flex flex-column align-items-center mt-4 font-weight-bold'}>
                    <div className={'d-flex align-items-center'}>
                        <img src={branch} style={{height: 35}}/>

                        <span>Personalize o tecido</span>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <img src={branch} style={{height: 35}}/>

                        <span>Personalize o modelo</span>
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <img src={branch} style={{height: 35}}/>

                        <span>Vestido sob medida</span>
                    </div>
                </div>

                <div className={'d-flex justify-content-center align-items-center'}>
                    <div className={'d-flex flex-center rounded px-4 p-2 h-40px shadow-sm mt-4'}
                         style={{backgroundColor: '#eeffee'}}>
                        <i className="bi bi-telephone-fill text-success mr-1" style={{fontSize: 16}}></i>
                        <a href={'https://api.whatsapp.com/send?phone=5567998403003'}
                           className={'text-success ms-2'}>WhatsApp</a>
                    </div>
                </div>


                <div className={'d-flex flex-column mt-5 mb-4'}>
                    <h5> Veja um pouco mais do processo</h5>
                    <span>Cada vestido é feito a mão, pensado desde a modelagem ate a costura, feitos detalhadamamente com todo carinho e amor, buscando sempre o maior conforto e qualidade.</span>
                </div>

                <InstagramVideoList/>
            </div>
        </div>
    )
};

ProductPageCustom.propTypes = {};

export default ProductPageCustom;
