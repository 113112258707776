import React, {useEffect, useState} from "react";
import moment from "moment";
import {globalMaskReal, maskPhone} from "../../helpers/Functions.js";
import {useAxios} from "../../helpers/Api";
import {CartProductListComponent} from "../../components/Cart/CartProductListComponent";
import {FaTruckArrowRight, FaTruckFast} from "react-icons/fa6";
import {FaCalendarCheck, FaPhone} from "react-icons/fa";
import correios from "../../media/images/correios.png"
export const FinishedOrderPage = (route) => {
    const api = useAxios();
    const [checkoutOrder, setCheckoutOrder] = useState(null);

    const getData = async () => {
        try {
            let res;
            if(route.match.params.identifier) {
                res = await api.get('/order/'+route.match.params.identifier)
            } else {
                res = await api.get('/order-iugu/'+route.match.params.iuguidentifier)
            }
            let aux = res.data.object;

            if(aux.iugu_status !== 'paid') {
                setTimeout(() => {
                    getData();
                },2000)
            }
            setCheckoutOrder({...aux});
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData();
        window.scrollToElement('body');
    },[]);

    return (
        <div >
            <div className={'container pt-5'}>
                {(checkoutOrder && checkoutOrder.iugu_status !== 'paid')&& <div style={{marginTop: 100, textAlign: 'center'}}>
                    <div style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        marginBottom: '40px',
                        fontFamily: 'Arial,Helvetica,sans-serif'
                    }}>
                        <p style={{
                            marginBottom: '9px',
                            color: '#181C32',
                            fontSize: '22px',
                            fontWeight: 700
                        }}><strong>{checkoutOrder.customer_name}, </strong>obrigada pelo seu pedido!</p>
                        <p style={{marginBottom: '2px', color: '#7E8299'}}>Estamos aguardando a aprovação do pagamento, isso leva alguns segundos</p>
                    </div>
                </div>}
                {(checkoutOrder && checkoutOrder.iugu_status === 'paid') &&
                    <table align="center" border={0} cellPadding={0} cellSpacing={0} width="100%" height="auto"
                           style={{borderCollapse: 'collapse'}}>
                        <tbody>
                        <tr>
                            <td align="center" valign="center" style={{textAlign: 'center', paddingBottom: '10px'}}>
                                {/*begin:Email content*/}
                                <div style={{textAlign: 'center',}}>

                                    <div className="checkmarkwraper">
                                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 52 52">
                                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                            <path className="checkmark__check" fill="none"
                                                  d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                        </svg>
                                    </div>
                                    {/*end:Media*/}
                                    {/*begin:Text*/}
                                    <div style={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        marginBottom: '40px',
                                        fontFamily: 'Arial,Helvetica,sans-serif'
                                    }}>
                                        <p style={{
                                            marginBottom: '9px',
                                            color: '#181C32',
                                            fontSize: '22px',
                                            fontWeight: 700
                                        }}><strong>{checkoutOrder.customer_name}, </strong>obrigada pelo seu pedido!</p>
                                        <p style={{marginBottom: '2px', color: '#7E8299'}}>Veja abaixo os detalhes de
                                            seu pedido</p>
                                    </div>


                                    {/*end:Text*/}
                                    {/*begin:Text*/}
                                    <div style={{
                                        textAlign: 'start',
                                        fontSize: '13px',
                                        fontWeight: 500,
                                        marginBottom: '27px',
                                        fontFamily: 'Arial,Helvetica,sans-serif'
                                    }}>

                                        <div className={'w-100 text-left mb-4'}>
                                            {checkoutOrder.items?.map((item, index) => <CartProductListComponent
                                                disabled={true}
                                                compact={true}
                                                item={item} key={index}/>
                                            )}
                                        </div>
                                        <div className={'d-flex align-items-center mb-2'}>
                                            <FaTruckArrowRight className={'mr-2 '} style={{fontSize: 16}}/>
                                            <span style={{color: '#5E6278', flex: 1}}>  Para ser entregue
                                                em <strong> {checkoutOrder?.customer_address_street + ' ' + checkoutOrder?.customer_address_street_number + ', ' + checkoutOrder?.customer_address_district + ', ' + (checkoutOrder?.customer_address_complement || '') + '  ' + checkoutOrder?.customer_address_cep} </strong> - {checkoutOrder?.customer_address_city_name} / {checkoutOrder?.customer_address_uf}
                                            </span>
                                        </div>
                                        <div className={'d-flex align-items-center mb-2'}>
                                            <FaCalendarCheck className={'mr-2'} style={{fontSize: 16}}/>
                                            <span style={{color: '#5E6278', flex: 1}}>Com data prevista de
                                                {checkoutOrder.delivery_type === 'PAC' ?
                                                    <strong> 6 a 8 dias úteis</strong> :
                                                    <strong> 3 a 5 dias úteis</strong>}
                                            </span>
                                        </div>

                                        <div className={'d-flex align-items-center mb-2'}>
                                            <FaPhone className={'mr-2'} style={{fontSize: 16}}/>

                                            <span style={{marginBottom: '2px', color: '#5E6278', flex: 1}}>Seu número de contato
                                                informado:
                                                <br/>
                                                <span style={{
                                                    color: '#50cd89',
                                                    fontWeight: 600
                                                }}> {maskPhone(checkoutOrder?.customer_contact_mobile_phone)}</span>
                                            </span>
                                        </div>


                                        <p style={{marginBottom: '2px', color: '#5E6278', marginTop: 20}}>Pago em
                                            - <strong>{moment(checkoutOrder.paid_at).format('DD/MM/YY')}</strong>
                                        </p>

                                        <p style={{marginBottom: '2px', color: '#5E6278', marginTop: 20}}>Metodo de
                                            Pagamento
                                            - <strong>{checkoutOrder.payment_method.indexOf('credit') !== -1 ? "Cartão de Crédito" : "PIX"}</strong>
                                        </p>
                                        <p style={{marginBottom: '2px', color: '#5E6278'}}>Valor:
                                            <span style={{
                                                color: '#2cab3d',
                                                fontSize: 20,
                                                fontWeight: 600
                                            }}> R$ {globalMaskReal(checkoutOrder.price)}</span>
                                        </p>
                                        {checkoutOrder.iugu_payment_installment &&
                                            <p style={{
                                                marginBottom: '2px',
                                                color: '#8a8d99'
                                            }}>Em {checkoutOrder.iugu_payment_installment}x de
                                                R$ {globalMaskReal(checkoutOrder.price / checkoutOrder.iugu_payment_installment)} </p>}

                                    </div>

                                    <div className={'mt-4 border-top pt-4'}>
                                        <h5>Acompanhe o rastreio de seu pedido</h5>
                                        <span> O link de acompanhamento do seu pedido ainda não foi gerado </span>
                                        <br/>
                                        <img src={correios} style={{maxHeight: 200}}/>
                                    </div>
                                </div>
                            </td>
                        </tr>


                        </tbody>
                    </table>}
            </div>

        </div>
    )
}

export default FinishedOrderPage
