import {FaCreditCard} from "react-icons/fa";
import {FaPix} from "react-icons/fa6";
import React from "react";
import {maskRealToView} from "../../helpers/Functions";

export const HowToPayComponent = ({handleChangeHowToPay, pixValue, creditCardValue, fullPrice}) => {
    return (
        <div className={''}>

            <div className="row  mt-lg-0">
                <div className="col-12 col-md-6 p-0 pr-lg-1">
                    <div className={'w-100 mb-3 shadow-hover mb-md-0 p-3 shadow-sm rounded d-flex justify-content-between align-items-center'}
                            style={{background: '#FAFAFA', cursor: "pointer", border: '1px solid #eaeaea'}}
                            onClick={() => handleChangeHowToPay('credit_card')}>
                            <div>
                                <FaCreditCard size={32}/>
                            </div>
                            <div className={'text-right'}>
                                <span style={{fontSize: 20}}>Cartão de crédito</span>
                                <div className={'d-flex align-items-end justify-content-end'}>
                                     <span style={{color: 'gray'}} className={'price_from mr-2'}>
                                         R$ {maskRealToView(fullPrice)}
                                    </span>
                                    <strong className="text-success" >R$ {maskRealToView(creditCardValue)} </strong>
                                </div>
                                <span>
                                    em até <strong> 10x </strong> de  <strong>R$ {maskRealToView(creditCardValue/10)}</strong> sem juros
                                </span>
                            </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 p-0 pl-lg-1">

                    <div className={'w-100 shadow-hover mb-3 mb-md-0 p-3 h-100 shadow-sm rounded d-flex justify-content-between align-items-center'}
                         style={{background: '#FAFAFA', cursor: "pointer", border: '1px solid #eaeaea'}}
                         onClick={() => handleChangeHowToPay('pix')}>
                        <div>
                            <FaPix size={32}/>
                        </div>
                        <div className={'text-right'}>
                            <span style={{fontSize: 20}}>PIX <span className={'text-success'} style={{fontSize: 16}}> (5% OFF)</span> </span>
                            <div className={'d-flex align-items-end justify-content-end'}>
                                     <span style={{color: 'gray'}} className={'price_from mr-2'}>
                                         R$ {maskRealToView(fullPrice)}
                                    </span>
                                <strong className="text-success" >R$ {maskRealToView(pixValue)} </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
