import {other} from "../SiteData";
import instaImg from "../media/images/product/dresses1/5.png";
import instaImg2 from "../media/images/product/dresses2/4.png";
import instaImg3 from "../media/images/product/dresses3/1.png";
import instaImg4 from "../media/images/product/dresses2/2.png";
import instaImg5 from "../media/images/product/dresses1/1.png";
import React, {useEffect, useState} from "react";
import 'react-modern-drawer/dist/index.css'
import Drawer from 'react-modern-drawer'
import branch from "../media/flower.png";
import {FaArrowRight, FaCartShopping, FaPix, FaShield, FaShopLock} from "react-icons/fa6";
import {copyToClipboard, CountCart, maskRealToView} from "../helpers/Functions";
import logo from "../media/images/logo.png";
import {FaArrowLeft, FaCreditCard, FaLock, FaMinusCircle, FaShieldAlt, FaWhatsapp} from "react-icons/fa";
import NumberFormat from "react-number-format";
import {toast} from "react-toastify";
import {useAxios} from "../helpers/Api";
import emptyCart from "../media/images/empty-cart.png"
import {useHistory} from "react-router-dom";
import PaymentComponent from "./PaymentComponent";
import {Accordion, removeShow} from "./Accordion";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import * as yup from "yup";
import ErrorMessage from "./ErrorMessage";
import useLocalStorage from "../helpers/storage";
import {PixComponent} from "./Cart/PixComponent";
import {HowToPayComponent} from "./Cart/HowToPayComponent";
import {AddressFormComponent} from "./Cart/AddressFormComponent";
import {PersonalDataComponent} from "./Cart/PersonalDataComponent";
import {CartProductListComponent} from "./Cart/CartProductListComponent";
import {useCart} from "../helpers/useCart";

export const CartComponent = ({open, handleOpen}) => {
    const [data, setData] = useState();
    const [step, setStep] = useState("CART");
    const [activeAccordion, setActiveAccordion] = useState("personal");
    const [stepAccordionCompleted, setStepAccordionCompleted] = useState([]);
    const [howToPay, setHowToPay] = useState("initial");
    const cartHook = useCart();
    const history = useHistory();
    const api = useAxios();
    const [loading, setLoading] = useState(true);
    const updateData = async () => {
        let res = await cartHook.getCartData();
        setData(res || {});
        if(res) {
            switch (res.step) {
                case 'payment-pending':
                case 'invoice-created':
                case 'payment-error':
                    handleSetStep('INITIATE-CHECKOUT');
                    handleChangeActiveAccordion('payment',['personal','address']);
                    if(res.payment_method === 'credit_card') {
                        setHowToPay('credit_card')
                    } else if(res.payment_method === 'pix') {
                        setHowToPay('pix')
                    }
                    break;
                case 'address-pending':
                    handleSetStep('INITIATE-CHECKOUT');
                    handleChangeActiveAccordion('address',['personal']);
                    break;
                case 'payment-done':
                    cartHook.removeCart();
                    history.push('pedido-concluido/'+res.identifier +window.location.search);
                    break;
                default:
                    handleChangeActiveAccordion('personal');
                    break;
            }
        }
    }

    const handleChangeActiveAccordion = (nextStep, forceConcludedSteps) => {
        const index = stepAccordionCompleted.indexOf(activeAccordion);
        let aux = stepAccordionCompleted

        if (index !== -1) {
            // Valor já existe no array, então remove
            aux.splice(index, 1);
        } else {
            // Valor não existe no array, então adiciona
            aux.push(activeAccordion);
        }
        removeShow(nextStep);
        setStepAccordionCompleted(forceConcludedSteps || aux);
        setActiveAccordion(nextStep);
    }

    const calcFullPrice = () => {
        let value = 0; //VALOR REAL
        let valuePix = 0; //VALOR NO PIX COM DESCONTO
        let fullValue = 0; //SOMA DE TUDO SEM DESCONTO E R$ 0 dos brindes
        let freight =  0;

        if(data) {
            freight =  Number(data.freight);
            value = Number(data.price) + Number(freight); //VALOR REAL
            valuePix = (data.price - (data.price * 0.05)) + Number(freight); //VALOR NO PIX COM DESCONTO
            fullValue = 0; //SOMA DE TUDO SEM DESCONTO E R$ 0 dos brindes

            data.items?.forEach((item) => {
                fullValue = fullValue + Number(item.price);
                item.product?.product_bonus_main?.forEach((subItem) => {
                    fullValue = fullValue + Number(subItem.bonus_product.price);
                })
            });
            fullValue = fullValue + freight;
        }

        return {value, fullValue, valuePix, freight}
    }

    const sendRequest = async (param) => {
        try {
             let res = await api.post('/iugu/invoice/cart/' + data.flora_cart_id + '/' + param,{cebola: 2});

             if(param === 'credit_card') {
                 setHowToPay(param);
                 setData({...res.data.object});
             } else {
                 if(res.data.object.iugu_data.secure_url) {
                     setHowToPay(param);
                     setData({...res.data.object});
                 } else {
                     toast.error('Ops, parece que ocorreu um erro tente novamente');
                 }
             }
        } catch (e) {
            console.log(e)
        }
    }
    const removeCart = async (item) => {
        let res = await cartHook.removeCartFromApi(item);
        setData({...res});
    }

    const handleChangeStep = (value) => {
        if(value === 'INITIATE-CHECKOUT' && data.step === 'pending') {
            cartHook.initiateCheckoutFlag(data);
        }
        setStep(value)
    }

    const updateFreight = (item) => {
        setData({...data, delivery_type: item.delivery_type, freight: item.freight});
    }

    const sendTokenAndInvoice = async (param, parcelas) => {

         try {
             let res = await api.post('/iugu/customer/'+ data.customer.iugu_customer_id +'/payment-method', {
                 token: param
             })

             let res2 = await api.post('/iugu/invoice/' + data.iugu_data.id + '/charge', {
                 token: param,
                 customer_payment_method_id: res.data.object.id,
                 months: Number(parcelas)
             });
             if(res2.data.executed) {
                 cartHook.removeCart();
                 history.push('pedido-concluido/'+res2.data.object.identifier);
             }
         } catch (e) {
             console.log(e)
         }

    }

    const removeArray = (item) => {
        let aux = stepAccordionCompleted
        const index = aux.indexOf(item);

        if (index !== -1) {
            aux.splice(index, 1);
        }

        setStepAccordionCompleted(aux)

    }


    const handleChoosePayment = (item) => {
        if(item !== 'initial') {
            sendRequest(item);
        } else {
            setHowToPay(item)
        }
    }

    const handleSetStep = (item) => {
        setStep(item);
    }

    useEffect(() => {
        if(open) updateData();
    },[open])

    useEffect(() => {
        if(data) {
            setLoading(false);
        }
    }, [data]);

    if(loading) return null;

    return (
        <Drawer
            open={open}
            onClose={()=> {
                setStep('CART');
                handleOpen(!open)
            }}
            direction='right'
            className='cart'
            lockBackgroundScroll={true}
            size={window.innerWidth > 800 ? 700 : window.innerWidth}
        >
            <div className={'d-flex flex-column h-100'} style={{overflow: "auto"}}>
                <div className={'position-absolute d-md-none'} onClick={()=> handleOpen(!open)} style={{right:16, top:16, zIndex:2}}>
                    <i className={'flaticon-close'}  style={{fontSize:20}}></i>
                </div>

                <div className="d-flex flex-wrap pb-2 pt-4 border-bottom">
                    <div className="col-12 col-md-6">
                        <img src={logo} alt="" style={{height: 50}}/>
                    </div>
                    <div className="col-12 col-md-6">
                        <span style={{color: 'gray'}}>
                        Feito com atenção em cada detalhe
                    </span>
                    </div>
                </div>

                <div className={'pt-3 pb-2 pr-3 pl-1  ml-1'}>
                    <div className={'d-flex  mb-0 align-items-center'}>
                        <img src={branch} style={{height: 35}}/>

                        <div className={'d-flex justify-content-between'} style={{flex: 1}}>
                            <span style={{
                                fontSize: 20,
                                fontWeight: "500",
                                marginBottom: -16,
                                color: '#2e2e2e'
                            }}> Meu <span>Carrinho</span></span>
                        </div>

                        <a className={'text-primary'} style={{cursor: "pointer"}} onClick={() => {
                            cartHook.removeCart();
                            setStep('CART');
                            handleOpen(!open);
                        }}> Limpar Carrinho</a>

                    </div>
                </div>
                {data?.items?.length > 0 ?
                    <>
                        <div className={'d-flex justify-content-end w-100'}>
                            <div className={'p-3 w-100'}>
                                {data.items?.map((item, index) => <CartProductListComponent
                                    disabled={data.iugu_invoice_id} removeCart={() => removeCart(item)} item={item}
                                    key={index}/>
                                )}
                            </div>
                        </div>

                        <div className={'p-0 m-0 p-3 mt-3'}
                             style={{borderTop: '1px solid gainsboro', background: '#fafafa'}}>

                            <div className={'d-flex flex-column'}>
                                {data.delivery_type && <div className={'d-flex justify-content-between'}>
                                    <span>
                                        Frete
                                    </span>
                                    <strong>
                                        {calcFullPrice().freight > 0 ? (' R$ ' + maskRealToView(calcFullPrice().freight)) : 'FRETE GRÁTIS'}
                                    </strong>
                                </div>}
                                <div className={'d-flex justify-content-between'}>
                                    <span>
                                        Subtotal
                                    </span>
                                    <span style={{color: 'gray'}} className={'price_from'}>
                                         R$ {maskRealToView(calcFullPrice().fullValue)}
                                    </span>
                                </div>

                                <div className={'d-flex align-items-end justify-content-end'}>
                                    <div className={'d-flex flex-column align-items-end'}>
                                        <div>
                                            <strong style={{fontSize: 25}}
                                                    className={'text-success'}>R$ {maskRealToView(calcFullPrice().valuePix)} </strong>
                                            <span className={'text-success'}>(5% OFF) no PIX</span>
                                        </div>
                                        <span>
                                            ou no cartão em até <strong> 10x </strong> de  <strong>R$ {maskRealToView(calcFullPrice().value / 10)}</strong> sem juros
                                        </span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </>
                    :
                    <div className={'text-center mt-5'}>
                        <img src={emptyCart} style={{maxHeight: 150}} alt="empty-cart"/>
                        <div className={'px-5'}>
                            <h4>Parece que você ainda não escolheu nenhum vestido</h4>
                            <div className={'d-flex justify-content-center mt-4'}>
                                <button className={'btn search-product mr-0 '} onClick={() => {
                                    handleOpen(!open)
                                    history.push("/categorias"+window.location.search)
                                }}>
                                    <FaCartShopping size={20}/>
                                    Buscar produtos
                                </button>
                            </div>
                        </div>
                    </div>
                }

                <>
                    {step === "CART" ?
                        <>
                            {data?.items?.length > 0 &&
                                    <div className={'p-3'} style={{flex: 1,
                                        display: "flex",
                                        alignItems: "end",
                                        flexDirection: "column",
                                        justifyContent: "center"}}>
                                        <button onClick={() => handleChangeStep("INITIATE-CHECKOUT")} className={'btn btn-success rounded-0 w-100 p-3 font-weight-bold'}>
                                            FINALIZAR COMPRA
                                        </button>

                                        <div onClick={() => handleOpen()} className={'btn btn-outline-success mt-3 rounded-0 w-100 p-3 '}>
                                            CONTINUAR COMPRANDO
                                        </div>
                                    </div>
                            }
                        </> :
                        <div style={{borderTop:' 1px solid gainsboro', width: '100%'}} className={'mb-4'}/>

                    }
                    {step === "INITIATE-CHECKOUT" &&
                        <span>
                            <div className={'d-flex'}
                                >
                              <button   onClick={() => setStep("CART")}
                                        className={'btn bg-transparent align-items-center p-0 pl-3 d-flex'} style={{gap: 10}}>  <FaArrowLeft/> Voltar</button>
                            </div>
                        </span>
                    }

                    {step === "INITIATE-CHECKOUT" &&
                        <div className={'px-0 px-md-4'}>
                            <Accordion.Group completedSteps={stepAccordionCompleted} idGroup={"accordion"}>
                                <Accordion.Root id={"personal"}
                                                className={'mt-3 shadow-sm'}
                                                onMenuSelected={(e) => setActiveAccordion(e)}
                                >
                                    <Accordion.Header activeMenu={activeAccordion}
                                                      onClick={() => removeArray( "personal")}
                                    >
                                        <div className={'d-flex align-items-center'} style={{gap:5}}>
                                            <span className={'index'}>1</span>
                                            <span style={{fontSize: 20, fontWeight: "600"}}>
                                                Dados Pessoais
                                            </span>
                                        </div>
                                        <small style={{color: "gray"}}>Solicitamos somente as informações essenciais para você fazer sua compra.</small>
                                    </Accordion.Header>
                                    <Accordion.Body isOpen={activeAccordion === "personal"}>
                                        <PersonalDataComponent disabled={data.iugu_invoice_id} handleChangeActiveAccordion={handleChangeActiveAccordion}/>
                                    </Accordion.Body>
                                </Accordion.Root>

                                <Accordion.Root  id={"address"}
                                                 className={'my-4'}
                                                 onMenuSelected={(e) => setActiveAccordion(e)}
                                >
                                    <Accordion.Header activeMenu={activeAccordion}
                                                      onClick={() => removeArray( "address")}
                                    >
                                        <div className={'d-flex align-items-center'} style={{gap:5}}>
                                            <span className={'index'} >2</span>
                                             <span style={{fontSize: 20, fontWeight: "600"}}>
                                                Entrega
                                             </span>
                                        </div>
                                        <small style={{color: 'gray'}}>Digite aqui seu endereço para entrega</small>
                                    </Accordion.Header>
                                    <Accordion.Body isOpen={activeAccordion === "address"}>
                                        <AddressFormComponent cart={data} updateFreight={(item) => updateFreight(item)} disabled={data.iugu_invoice_id} handleChangeActiveAccordion={handleChangeActiveAccordion}/>
                                    </Accordion.Body>
                                </Accordion.Root>

                                <div className={'d-flex align-items-center justify-content-end m-2'}>
                                    <div className={'bg-light rounded p-2 align-items-center d-flex'} style={{fontSize: 13, gap:10}}>
                                        <FaLock className={'text-success'} size={28} style={{marginRight: 5}}/>
                                        <span>Este é um pagamento seguro, realizado em ambiente criptografado.</span>
                                    </div>
                                </div>

                                <Accordion.Root  id={"payment"}
                                                 className={'mb-4 mt-2'}
                                                 onMenuSelected={(e) => setActiveAccordion(e)}
                                >
                                    <Accordion.Header activeMenu={activeAccordion}
                                                      onClick={() => removeArray( "payment")}
                                    >
                                        <div className={'d-flex align-items-center'} style={{gap:5}}>
                                            <span className={'index'} >3</span>
                                            <span style={{fontSize: 20, fontWeight: "600"}}>
                                                Pagamento
                                             </span>
                                        </div>
                                        <small style={{color: 'gray'}}>
                                            Escolha abaixo uma forma de pagamento
                                        </small>
                                    </Accordion.Header>
                                    <Accordion.Body isOpen={activeAccordion === "payment"}>
                                        <div>

                                            {data.iugu_invoice_id === null&&<div className={`transition ${howToPay === "initial" ? "show" : ""}`}>
                                                <HowToPayComponent
                                                    fullPrice={calcFullPrice().fullValue}
                                                    creditCardValue={calcFullPrice().value}
                                                    pixValue={calcFullPrice().valuePix}
                                                    handleChangeHowToPay={(mode) => handleChoosePayment(mode)}/>
                                            </div>}
                                            <div className={`transition ${howToPay === "credit_card" ? "show" : ""}`}>
                                                <PaymentComponent price={calcFullPrice().value}
                                                                  sendTokenAndInvoice={(item, parcels) => sendTokenAndInvoice(item, parcels)}
                                                />
                                            </div>

                                            <div className={`transition ${howToPay === "pix" ? "show" : ""}`}>
                                               <div>
                                                   <PixComponent
                                                       show={howToPay === "pix"}
                                                       pixValue={calcFullPrice().valuePix}
                                                       url={data?.iugu_data?.pix?.qrcode} code={data?.iugu_data?.pix?.qrcode_text}
                                                   />
                                               </div>
                                            </div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Root>
                                <div onClick={() => window.gtag &&window.gtag("event", "whats_cart_click")} className={'d-flex align-items-center justify-content-end pb-3 pr-2'}>
                                    <a  className={' align-items-center d-flex'} style={{cursor: 'pointer'}} href={window.innerWidth < 768? 'https://wa.me/5567991797649' : 'https://web.whatsapp.com/send?phone=5567991797649'}>
                                        <FaWhatsapp className={'text-success'} style={{marginRight: 5, fontSize: 25}}/>
                                        <span style={{fontSize: 13, }} className={'text-success'}>Esta com alguma dúvida? <br/> entre em contato pelo whatsapp</span>
                                    </a>
                                </div>
                            </Accordion.Group>
                        </div>
                    }
                </>

            </div>
        </Drawer>
    )
}

