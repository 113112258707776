import React, {useEffect, useState} from 'react'
import ProductsComponent from "../../Categories/Components/ProductsComponent";
import {CollectionProducts} from "../../../SiteData";
import branch from "../../../media/branch.png"
import {useAxios} from "../../../helpers/Api";
import ProductsNewComponent from "../../Categories/Components/ProductsNewComponent";
import ProductsComponentCarousel from "../../../components/ProductsComponentCarousel";
const MostNewComponent = ({hideLabel}) => {
    const [productList, setProductList] = useState([]);
    const api = useAxios();

    const getDressList = async () => {

        try {
            let res = await api.get('/product/variations/list?show_home=true&order_by=new');
            if (res.data.object.length > 0) {
                setProductList(res.data.object);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getDressList();
    }, []);

    return (
        <div className={'most-saled pb-0'}>
            <div className="container ">
                {!hideLabel && <>{/*<div className={'d-flex w-100 justify-content-center'}>
                    <img src={branch} style={{height: 50}}/>
                </div>*/}
                <h2 className={'text-center mb-4 font-weight-super-light'} style={{fontWeight: '500',letterSpacing: 5}}>NOVIDADES</h2>
                </>}
                <ProductsComponentCarousel data={productList} newCategory={true}/>
            </div>
        </div>
    )
}

export default MostNewComponent
