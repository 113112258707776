
import banner_home from './media/home/Principal-01.jpg'
import banner_mobile from './media/home/Principal-01-Mobile.jpg'
import dress11 from "./media/images/product/dresses1/1.png";
import dress12 from "./media/images/product/dresses1/2.png";
import dress13 from "./media/images/product/dresses1/3.png";
import dress14 from "./media/images/product/dresses1/4.png";
import dress15 from "./media/images/product/dresses1/5.png";
import dress16 from "./media/images/product/dresses1/6.png";
import dress17 from "./media/images/product/dresses1/7.png";
import dress21 from "./media/images/product/dresses2/1.png";
import dress22 from "./media/images/product/dresses2/2.png";
import dress23 from "./media/images/product/dresses2/3.png";
import dress24 from "./media/images/product/dresses2/4.png";
import dress25 from "./media/images/product/dresses2/5.png";
import dress26 from "./media/images/product/dresses2/6.png";
import dress27 from "./media/images/product/dresses2/7.png";
import dress28 from "./media/images/product/dresses2/8.png";
import dress31 from "./media/images/product/dresses3/1.png";
import dress32 from "./media/images/product/dresses3/2.png";
import dress33 from "./media/images/product/dresses3/3.png";
import dress34 from "./media/images/product/dresses3/4.png";
import dress35 from "./media/images/product/dresses3/5.png";
import dress36 from "./media/images/product/dresses3/6.png";
import dress37 from "./media/images/product/dresses3/7.png";
import dress61 from "./media/images/product/dresses6/1.png";
import dress62 from "./media/images/product/dresses6/2.png";
import dress63 from "./media/images/product/dresses6/3.png";
import dress64 from "./media/images/product/dresses6/4.png";
import dress65 from "./media/images/product/dresses6/5.png";
import dress71 from "./media/images/product/dresses7/1.png";
import dress72 from "./media/images/product/dresses7/2.png";
import dress73 from "./media/images/product/dresses7/3.png";
import dress74 from "./media/images/product/dresses7/4.png";
import dress75 from "./media/images/product/dresses7/5.png";
import dress81 from "./media/images/product/dresses9/1.jpg";
import dress82 from "./media/images/product/dresses9/2.jpg";
import dress83 from "./media/images/product/dresses9/3.jpg";
import dress84 from "./media/images/product/dresses9/4.jpg";
import dress101 from "./media/images/product/dresses10/1.jpg";
import dress102 from "./media/images/product/dresses10/2.jpg";
import dress103 from "./media/images/product/dresses10/3.jpg";
import custom1 from "./media/images/product/custom/1.png";
import custom2 from "./media/images/product/custom/2.png";
import custom3 from "./media/images/product/custom/3.png";
import custom4 from "./media/images/product/custom/4.png";
import custom5 from "./media/images/product/custom/5.png";
import custom6 from "./media/images/product/custom/6.png";
import custom7 from "./media/images/product/custom/7.png";
import cloth1 from "./media/images/cloths/1.png"
import cloth2 from "./media/images/cloths/2.png"
import cloth3 from "./media/images/cloths/3.png"
import cloth4 from "./media/images/cloths/4.png"
import cloth5 from "./media/images/cloths/5.png"
import cloth6 from "./media/images/cloths/6.png"
import manga1 from "./media/images/customizations/manga1.png"
import manga2 from "./media/images/customizations/manga2.png"
import manga3 from "./media/images/customizations/manga3.png"
import manga4 from "./media/images/customizations/manga4.png"
import gola1 from "./media/images/customizations/gola1.png"
import gola2 from "./media/images/customizations/gola2.png"
import gola3 from "./media/images/customizations/gola3.png"
import gola4 from "./media/images/customizations/gola4.png"
import decotecostas1 from "./media/images/customizations/decotecostas1.png"
import decotecostas2 from "./media/images/customizations/decotecostas2.png"
import decotecostas3 from "./media/images/customizations/decotecostas3.png"
import cinto1 from "./media/images/customizations/cinto1.png"
import cinto2 from "./media/images/customizations/cinto2.png"
import product1 from "./media/images/product/dresses1/1.png";
import product2 from "./media/images/product/dresses2/1.png";
import product3 from "./media/images/product/dresses3/1.png";
import product4 from "./media/images/product/dresses6/1.png";

import cat1 from "./media/home/Categoria-01.png";
import cat2 from "./media/home/Categoria-02.png";
import cat3 from "./media/home/Categoria-03.png";


export const interpreter = {
  cloth: 'Tipo do tecido',
  ornaments: 'Tipo do Ornamento',
  collar: 'Colarinho',
  sleeve: 'Manga',
  back_collar: 'Parte de Tras',
  size: 'Tamanho do Vestido',
  sizes_dress: 'Altura da saia',
  name: 'Nome do vestido',
}


export const SliderProductsList = [
  {
    image: product1,
    title: `Vestido de Criança "Primavera Encantada"`,
    description: "Encante-se com o nosso Vestido de Criança \"Primavera Encantada\", uma obra-prima artesanal que traz à vida a alegria e a beleza da estação das flores. Cada detalhe deste vestido é cuidadosamente confeccionado à mão, com amor e dedicação, para garantir que sua pequena princesa se sinta como a mais especial das flores no jardim."
  },
  {
    image: product2,
    title: `Vestido Artesanal para Crianças "Florescência Feliz"`,
    description: "Celebre a alegria da infância com nosso Vestido Artesanal para Crianças \"Florescência Feliz\". Feito à mão com amor e dedicação, este vestido é uma obra-prima de artesanato. Sua pequena princesa ficará encantada com os detalhes encantadores e o conforto incomparável. Perfeito para brincadeiras e momentos especiais, este vestido irradia felicidade e beleza."
  },
  {
    image: product3,
    title: `Vestido Artesanal para Criança "Sonhos de Infância"`,
    description: "Encante-se com o nosso Vestido de Criança \"Primavera Encantada\", à mão, com amor e dedicação, para garantir que sua pequena princesa se sinta como a mais especial das flores no jardim."
  },
  {
    image: product4,
    title: `Vestido de Criança "Primavera Encantada"`,
    description: "Encante-se com o nosso Vestido de Criança \"Primavera Encantada\", uma obra-prima artesanal que traz à vida a alegria e a beleza da estação das flores. Cada detalhe deste vestido é cuidadosamente confeccionado à mão, com amor e dedicação, para garantir que sua pequena princesa se sinta como a mais especial das flores no jardim."
  },

]
export const other = {
  cell_phone: '',
  instagram: 'https://www.instagram.com/_atelieflora_/',
  facebook: 'https://m.facebook.com/100089569324310/',
  cloths: [{
    image: cloth1,
    title: 'Tecido 1'
  },{
    image: cloth2,
    title: 'Tecido 2'
  },{
    image: cloth3,
    title: 'Tecido 3'
  },{
    image: cloth4,
    title: 'Tecido 4'
  },{
    image: cloth5,
    title: 'Tecido 5'
  },{
    image: cloth6,
    title: 'Tecido 6'
  }],
  sizes: ['3 Mêses', '6 Mêses', '9 Mêses', '1 Ano', '2 Anos', '3 Anos', '4 Anos' , '5 Anos', '6 Anos'],
  size_dress: ['Saia no joeho', 'Saia midi'],
  ornaments: [{title: 'Acompanhar Lacinhos de cabelo x1', label: "Lacinhos de cabelo", value: 14, options: ['Sim', 'Não']}, {title: 'Tapa fralda', label: 'Tapa fralda', value: 37.90, options: ['Sim', 'Não']}, {title: 'Forro', label: 'Forro', value: 15, options: ['Sim', 'Não']}],
  trapezio_ornaments: [{title: 'Acompanhar Lacinhos de cabelo x1', label: "Lacinhos de cabelo", value: 14, options: ['Sim', 'Não']}, {title: 'Tapa fralda', label: 'Tapa fralda', value: 37.90, options: ['Sim', 'Não']}, {title: 'Forro', default:'Sim', label: 'Forro', value: 0, options: ['Sim']}],
  collar: [{
    image: gola1,
    title: 'gola1'
  },
  {
    image: gola2,
    title: 'gola2'
  },
  {
    image: gola3,
    title: 'gola3'
  },
  {
    image: gola4,
    title: 'gola4'
  }],
  sleeve: [{
    image: manga1,
    title: 'manga1'
  },{
    image: manga2,
    title: 'manga2'
  },{
    image: manga3,
    title: 'manga3'
  },{
    image: manga4,
    title: 'manga4'
  }],
  back_collar: [{
    image: decotecostas1,
    title: 'decotecostas1'
  },{
    image: decotecostas2,
    title: 'decotecostas2'
  },{
    image: decotecostas3,
    title: 'decotecostas3'
  }],
  cint: [{
    image: cinto1,
    title: 'cinto1'
  },{
    image: cinto2,
    title: 'cinto2'
  }]
}

export const CollectionProducts = [
  {
    id: '6',
    name: 'Jasmine',
    presentation: 'Este vestido tem uma saia franzida que proporciona volume e movimento. O forro aparente com babado traz um toque delicado e romântico à peça. As mangas estilo "fru fru" e o laço nas costas adiciona um toque de fofura',
    description: <>
      Este vestido tem uma saia franzida que proporciona volume e movimento. O forro aparente com babado traz um toque delicado e romântico à peça. As mangas estilo "fru fru" e o laço nas costas adiciona um toque de fofura.
      Vista sua flor com um dos nosso modelos🌸
    </>,
    images: [dress84,dress81,dress83, dress82],
    cloths: other.cloths,
    sizes: other.sizes,
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 215,00',
    priceNumber: 215.00,
    from_price: 'R$ 279,00',
    from_price_number: 279.00,
    discount: '30% Off',
    most_saled: true,
    category: 'dresses',
  },
  {
    id: '1',
    name: 'Iris',
    presentation: 'O vestido Iris é uma peça única e encantadora que vai deixar a sua flor ainda mais linda e charmosa. Com a opção de escolha de tecido, você poderá escolher entre diferentes opções para o vestido, de acordo com o estilo da sua filha...',
    description: <>
        O vestido Iris é uma peça única e encantadora que vai deixar a sua flor ainda mais linda e charmosa. Com a opção de escolha de tecido, você poderá escolher entre diferentes opções para o vestido, de acordo com o estilo da sua filha.
        <br/><br/>Neste vestido, você pode optar por incluir o forro para garantir o conforto da criança durante todo o uso.
        <br/> <br/>O modelo conta um decote padrão, manga copinho, gola boneca com renda e um cinto lateral para dar um toque de elegância e definir a silhueta da criança, com uma saia franzida que ajuda a criar um movimento fluido ao caminhar. </>,
    images: [dress11,dress13,dress16, dress17, dress15, dress14, dress12],
    cloths: other.cloths,
    sizes: other.sizes,
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 220,00',
    priceNumber: 220.00,
    from_price: 'R$ 280,00',
    from_price_number: 280.00,
    discount: '30% Off',
    most_saled: true,
    category: 'dresses',
  },
  {
    id: '2',
    name: 'Dália',
    presentation: 'O vestido Dália é perfeito para uma ocasião especial ou simplesmente para dar um toque de charme ao guarda-roupa da sua florzinha, confeccionado em um tecido leve e confortável para garantir o conforto da sua flor...',
    description:
        <>
          O vestido Dália é uma peça encantadora e delicada, perfeita para uma ocasião especial ou simplesmente para dar um toque de charme ao guarda-roupa da sua florzinha. Este vestido é confeccionado em um tecido leve e confortável, com a opção de forro para garantir o conforto da criança durante todo o uso.
          <br/><br/>Além disso, você tem a opção de escolher o tecido para deixar o vestido ainda mais exclusivo e até alterar a altura da saia.
          <br/><br/>O modelo conta um decote redondo com babado, manga fru-fru e um cinto de amarração nas costas para dar um toque de elegância e definir a silhueta da criança. A saia é franzida que ajuda a criar um movimento fluido ao caminhar.
          <br/><br/>Você pode optar por adicionar outros detalhes que desejar para criar um vestido personalizado e único para a sua filha.
        </>,

    images: [dress25,dress22,dress23, dress21, dress24, dress26, dress27,dress28],
    cloths: other.cloths,
    sizes: other.sizes,
    kids_images: [],
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 195,90',
    priceNumber: 195.90,
    from_price: 'R$ 245,90',
    from_price_number: 245.90,
    discount: '30% Off',
    most_saled: true,
    category: 'sets',
  },
  {
    id: '7',
    name: 'Daisy',
    presentation: 'Mais um modelinho feito com todo cuidado aos detalhes. 🪡\n' +
        'Este é o modelo Daisy, seu destaque está no charme do babado de laise e seu conforto.\n' +
        '\n' +
        'Encomende o seu e escolha o desenho da laise, a cor do tecido e faça sob medida para sua florzinha.',
    description: <>
      Mais um modelinho feito com todo cuidado aos detalhes. 🪡
      Este é o modelo Daisy, seu destaque está no charme do babado de laise e seu conforto.

      Encomende o seu e escolha o desenho da laise, a cor do tecido e faça sob medida para sua florzinha.
    </>,
    images: [dress103,dress102,dress101],
    cloths: other.cloths,
    sizes: other.sizes,
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 245,00',
    priceNumber: 245.00,
    from_price: 'R$ 299,00',
    from_price_number: 299.00,
    discount: '30% Off',
    most_saled: false,
    category: 'sets',
  },
  {
    id: '3',
    name: 'Magnólia',
    presentation: 'Com saia e babados franzidos garantem um movimento fluido, enquanto o decote redondo e os bolsos frontais adicionam um toque de elegância...',
    description: <>
      Se você está procurando um vestido infantil que seja lindo, confortável e único, eu tenho a peça perfeita para sua princesa! O vestido Magnólia é uma opção encantadora que vai deixar sua filha ainda mais fofa e charmosa.
      <br/><br/>Com a opção de escolha de tecido, você pode personalizar o vestido para atender ao estilo e às preferências da sua filha,  além disso você pode optar por incluir o forro para garantir o conforto da criança durante todo o uso.
      <br/><br/>O vestido é perfeito para várias ocasiões, desde um passeio casual até um evento especial, como uma festa de aniversário ou um casamento. Com saia e babados franzidos garantem um movimento fluido, enquanto o decote redondo e os bolsos frontais adicionam um toque de elegância.
      <br/><br/>Estou muito animada em poder oferecer essa opção encantadora e personalizada para você que procura algo especial para sua filha. Com opções de personalização, você pode garantir que o vestido seja exclusivo e único para sua filha.
    </>,
    images: [dress33,dress36,dress35, dress31, dress32, dress34, dress37],
    cloths: other.cloths,
    sizes: other.sizes,
    kids_images: [],
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 215,90',
    priceNumber: 215.90,
    from_price: 'R$ 305,90',
    from_price_number: 305.90,
    discount: '30% Off',
    most_saled: false,
    category: 'accessory',
  },
  {
    id: '4',
    name: 'Tulípa Angelique',
    presentation: 'Com detalhes em renda na gola e babados franzidos nas mangas e barras conferem um toque especial à peça...',
    description: <>
      O vestido infantil trapézio é uma peça charmosa e elegante, que possui uma modelagem evasê. Com detalhes em renda na gola e babados franzidos nas mangas e barras conferem um toque especial à peça.
      <br/><br/> Combinado com acessórios adequados, como sapatos, laços e tiaras, o vestido evasê pode criar looks encantadores e sofisticados para sua flor...
    </>,
    images: [dress61,dress63,dress62, dress64, dress65],
    cloths: other.cloths,
    sizes: other.sizes,
    kids_images: [],
    ornaments: other.trapezio_ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 199,90',
    priceNumber: 199.90,
    from_price: 'R$ 260,90',
    from_price_number: 260.90,
    discount: '24% off',
    most_saled: false,
    category: 'accessory',
  },
  {
    id: '5',
    name: '3 Marias',
    presentation: 'O 3 Marias é uma peça encantadora e delicada. Feito com tecidos de alta qualidade e acabamentos a mão, esse vestido possui um design sofisticado e atemporal...',
    description: <>
      O 3 Marias é uma peça encantadora e delicada. Feito com tecidos de alta qualidade e acabamentos a mão, esse vestido possui um design sofisticado e atemporal, para sua pequena nunca sair de moda.
        <br/><br/>O vestido 3 Marias está disponível em diversas cores e estampas, desde as mais suaves até as mais vibrantes e alegres.
        <br/><br/>É uma opção perfeita para ocasiões especiais, como casamentos, batizados, aniversários e festas de fim de ano, além de ser uma excelente escolha para ensaios fotográficos e eventos formais.
      </>,
    images: [dress71,dress72,dress73, dress74, dress75],
    cloths: other.cloths,
    sizes: other.sizes,
    kids_images: [],
    ornaments: other.ornaments,
    sizes_dress: other.size_dress,
    price: 'R$ 109,90',
    priceNumber: 109.90,
    from_price: 'R$ 139,90',
    from_price_number: 139.90,
    discount: '22% off',
    most_saled: false,
    category: 'accessory',
  },

];

export const PersonalizedDress = {
  name: 'Vestido Personalizado',
  description: '',
  images: [custom1, custom2, custom3,custom4, custom5, custom6, custom7],
  cloths: other.cloths,
  sizes: other.sizes,
  ornaments: other.ornaments,
  collar: other.collar,
  sleeve: other.sleeve,
  cint: other.cint,
  back_collar: other.back_collar,
  price: '',
  from_price: ''
}


export const HomePageData = {
  default_banner: banner_home,
  banner_mobile: banner_mobile,
  default_text: <div className="slider-text mob-align-left">
    <h1 className="animated fadeIn" style={{fontSize: 40}}><span>Coleção de Lançamento </span> 2023 </h1>
    <p className="animated fadeIn">Mamãe, sua filha merece o melhor! Deixe suas princesas ainda mais encantadoras com esses vestidos.</p>
    <a className="animated fadeIn btn-two" href="#">Veja agora!</a>
  </div>,
  promo_spots: [
        <a   href={window.location.origin + '/categorias/2'} target={'_blank'}>
          <div className="sin-banner align-items-center">
            <img src={cat1} alt="" />

          </div>
          {/* /.sin-banner */}
        </a>
    ,
    <a  href={window.location.origin + '/categorias/1'} target={'_blank'}>
        <div className="sin-banner style-two">
          <img src={cat2} alt="" />

        </div>
        {/* /.sin-banner */}
      </a>,
    <a  href={window.location.origin + '/categorias/3'} target={'_blank'}>
        <div className="sin-banner style-two w-100">
          <img src={cat3} alt="" />

        </div>
        {/* /.sin-banner */}
      </a>
  ],
  my_clients: [],
  instagram_imgs: [],


}
