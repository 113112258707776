import {other} from "../SiteData";
import instaImg from "../media/images/instagram/photos/img.png";
import instaImg1 from "../media/images/instagram/photos/img1.jpg";
import instaImg2 from "../media/images/instagram/photos/img2.jpg";
import instaImg3 from "../media/images/instagram/photos/img3.jpg";
import instaImg4 from "../media/images/instagram/photos/img4.jpg";
import React from "react";

import {BsWhatsapp} from "react-icons/bs";
export const InstagramList = () => {

    return (
        <section className="instagram-area-two">
            <div onClick={() => window.open(other.instagram, '_blank')}  className="row owl-theme m-0 p-0 position-relative">

                <div style={{position: 'absolute', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100}}>
                    <a href={other.instagram} style={{border: '1px solid white', borderRadius: 10}}>
                        <div className={'m-1 rounded p-2 bg-white'}>
                            <i className="fab fa-instagram text-dark mr-2"></i>

                            <span style={{fontWeight: 500, color: '#474747'}}> Me siga no Instagram </span></div>
                    </a>
                </div>

                <div className="sin-instagram col-6 col-md-3 p-0">
                    <img style={{height: 200, width: '100%'}} src={instaImg} alt="" />

                </div>
                <div className="sin-instagram col-6 col-md-3 p-0">
                    <img style={{height: 200, width: '100%'}} src={instaImg1} alt="" />

                </div>
                <div className="sin-instagram col-6 col-md-3 p-0">
                    <img style={{height: 200, width: '100%'}} src={instaImg4} alt="" />

                </div>
                <div className="sin-instagram col-6 col-md-3 p-0">
                    <img style={{height: 200, width: '100%'}} src={instaImg3} alt="" />

                </div>

            </div>
        </section>
    )
}

