import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/styles.scss'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
    dsn: "https://5dc7af726182f406ec7d2a3f361f1430@o4506623315345408.ingest.us.sentry.io/4507008234946560",
    integrations: [
        Sentry.browserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/atelieflora\.com/],
});


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus:false
        },
    },
})

root.render(
      <Sentry.ErrorBoundary fallback={({ error, componentStack, resetError }) => (
          <React.Fragment>
              <div>Parece que você encontrou um erro, iremos corrigir o mais rápido possível!</div>                <button
              onClick={() => {resetError();}}>
              Clique aqui para recarregar
          </button>
          </React.Fragment>
      )} showDialog>
          <QueryClientProvider client={queryClient}>
            <App />
            {/*<ReactQueryDevtools initialOpen={false}/>*/}
          </QueryClientProvider>
      </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
