import React from "react";


const Products = () => {
    const array = new Array(3).fill("1");

    return (
        <div className={'row'}>
            {array.map((item,index) => {
                return (
                    <div className={'col-12 col-md-4 mb-4'}>
                        <div>
                            <div key={index} className="skeleton" style={{height: 300, width: '100%'}}/>
                        </div>
                        <div className={'text-right'}>
                            <div key={index} className="skeleton col-6 col-md-6" style={{height: 20}}/>
                        </div>
                        <div className={'text-center'}>
                            <div key={index} className="skeleton col-9 col-md-8" style={{height: 30}}/>
                        </div>
                        <div className={'text-center'}>
                            <div key={index} className="skeleton col-12 col-md-10" style={{height: 120}}/>
                        </div>

                        {/*<div className={'text-center'}>*/}
                        {/*    <div key={index} className="skeleton col-6 col-md-8" style={{height: 40}}/>*/}
                        {/*</div>*/}
                        {/*<div className={'text-center'}>*/}
                        {/*    <div key={index} className="skeleton col-10 col-md-8" style={{height: 20}}/>*/}
                        {/*</div>*/}

                        {/*<div className={'d-flex justify-content-center'}>*/}
                        {/*    <div key={index} className="skeleton col-3 mr-2" style={{height: 20}}/>*/}
                        {/*    <div key={index} className="skeleton col-1" style={{height: 20}}/>*/}
                        {/*</div>*/}

                    </div>
                )
            })}

        </div>
    )
}

const Categories = () => {
    const array = new Array(5).fill("1");

    return (
        <div className={'row py-3'}>
            {array.map((item,index) => {
                return (
                    <div className={'col-md-2 px-1'}>
                        <div key={index} className="skeleton w-100" style={{height: 20}}/>
                    </div>
                )
            })}
        </div>
    )
}

export const Skeleton = {
    Products: Products,
    Categories:Categories,
}